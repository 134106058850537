// AOI attribute expression interfaces

import { TAoiAttributeType } from "./AoiGroupInterfaces";



export interface IAoiAttribExpression
{
  clauses: IAoiExpressionClause[];
  operator: TAoiExpressionOperator;
}

export type TAoiExpressionOperator = "and" | "or";

export interface IAoiExpressionClause
{
  id: number;  // internal id used as a react key
  attribute_id?: number;
  type?: TAoiAttributeType;
  operator: TAoiExpressionStringOperator | TAoiExpressionNumberOperator;
  value?: string;
  value_max?: string; // NOTE: it's a string so the user can freely type floats (with - and . chars) without validation interfering

}

export const AoiExpressionStringOperators = [ "is equal to", 
                                              "is not equal to",
                                              "begins with",
                                              "does not begin with",
                                              "ends with",
                                              "does not end with",
                                              "contains",
                                              "does not contain",
                                              "is empty",
                                              "is not empty"] as const;
export type TAoiExpressionStringOperator = typeof AoiExpressionStringOperators[number];

export const AoiExpressionNumberOperators = [ "is equal to", 
                                              "is not equal to",
                                              "is less than",
                                              "is less than or equal to",
                                              "is greater than",
                                              "is greater than or equal to",
                                              "is in range",
                                              "is not in range",
                                              "is empty",
                                              "is not empty"] as const;
export type TAoiExpressionNumberOperator = typeof AoiExpressionNumberOperators[number];
