// Edit layer attributes

import { Checkbox, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { theme_errorRed, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import { ILayer, IVectorLayerAttribute } from '../../Layers/LayerInterfaces';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { ChangeEvent, useState } from "react";
import { CustomTextField } from "./EditLayer";


const ATTRIB_DISPLAY_NAME_MAX_LENGTH = 50;
const ATTRIB_DESCRIPTION_MAX_LENGTH = 250;
const ATTRIB_INFO_URL_MAX_LENGTH = 250;
const ATTRIB_UNIT_MAX_LENGTH = 50;



// A copy of the IVectorLayerAttribute interface, but we add the "edited" boolean which keeps 
// track of which attributes the user has changed.

export interface IVectorLayerAttributeEdited extends IVectorLayerAttribute
{
  edited?: boolean;
}


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditLayerAttributesProps
{
  layer?: ILayer;
  newLayerAttribs?: IVectorLayerAttributeEdited[];
  setNewLayerAttribs: any;
  setAttribListWasChanged: any;
}

//-------------------------------------------------------------------------------
// Edit layer attributes
//-------------------------------------------------------------------------------
export function EditLayerAttributes(props: EditLayerAttributesProps) 
{
  const [attribBeingEdited, setAttribBeingEdited] = useState<IVectorLayerAttributeEdited|undefined>(undefined);







  //-------------------------------------------------------------------------------
  // Returns the number of visible attributes.
  //-------------------------------------------------------------------------------
  function GetVisibleAttributeCount(): number
  {
    if(!props.newLayerAttribs) return 0;

    let count: number = 0;
    props.newLayerAttribs.map(a => a.is_visible ? count++ : undefined);

    return count;
  }

  //-------------------------------------------------------------------------------
  // Toggle the "Is Visible" state of an attribute.
  //-------------------------------------------------------------------------------
  function OnToggleIsVisible(attribute: IVectorLayerAttributeEdited)
  {
    if(!props.newLayerAttribs || !attribute) return;

    const updatedAttribute: IVectorLayerAttributeEdited = 
    {
      ...attribute,
      is_visible: !attribute.is_visible,
      edited: true
    }

    const updatedAttribList: IVectorLayerAttributeEdited[] = props.newLayerAttribs.map(entry => entry.attribute_id === attribute.attribute_id ? updatedAttribute : entry);

    // Update the attribute list
    props.setNewLayerAttribs(updatedAttribList);

    // Tell calling code that the attribute list was changed (so it knows to save it)
    props.setAttribListWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // Toggle the EDIT state of the clicked attribute.
  //-------------------------------------------------------------------------------
  function OnEditAttribute(attribute: IVectorLayerAttributeEdited)
  {
    setAttribBeingEdited(attribute.attribute_id === attribBeingEdited?.attribute_id ? undefined : attribute);
  }

  //-------------------------------------------------------------------------------
  // The display name has changed for an attribute.
  //-------------------------------------------------------------------------------
  function OnDisplayNameChanged(attribute: IVectorLayerAttributeEdited, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!props.newLayerAttribs || !attribute) return;

    const newValue: string = event.target.value;

    const updatedAttribute: IVectorLayerAttributeEdited = 
    {
      ...attribute,
      display_name: newValue,
      edited: true
    }

    const updatedAttribList: IVectorLayerAttributeEdited[] = props.newLayerAttribs.map(entry => entry.attribute_id === attribute.attribute_id ? updatedAttribute : entry);

    // Update the attribute list
    props.setNewLayerAttribs(updatedAttribList);

    // Tell calling code that the attribute list was changed (so it knows to save it)
    props.setAttribListWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The description has changed for an attribute.
  //-------------------------------------------------------------------------------
  function OnDescriptionChanged(attribute: IVectorLayerAttributeEdited, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!props.newLayerAttribs || !attribute) return;

    const newValue: string = event.target.value;

    const updatedAttribute: IVectorLayerAttributeEdited = 
    {
      ...attribute,
      description: newValue,
      edited: true
    }

    const updatedAttribList: IVectorLayerAttributeEdited[] = props.newLayerAttribs.map(entry => entry.attribute_id === attribute.attribute_id ? updatedAttribute : entry);

    // Update the attribute list
    props.setNewLayerAttribs(updatedAttribList);

    // Tell calling code that the attribute list was changed (so it knows to save it)
    props.setAttribListWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The info URL has changed for an attribute.
  //-------------------------------------------------------------------------------
  function OnInfoURLChanged(attribute: IVectorLayerAttributeEdited, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!props.newLayerAttribs || !attribute) return;

    const newValue: string = event.target.value;

    const updatedAttribute: IVectorLayerAttributeEdited = 
    {
      ...attribute,
      info_url: newValue,
      edited: true
    }

    const updatedAttribList: IVectorLayerAttributeEdited[] = props.newLayerAttribs.map(entry => entry.attribute_id === attribute.attribute_id ? updatedAttribute : entry);

    // Update the attribute list
    props.setNewLayerAttribs(updatedAttribList);

    // Tell calling code that the attribute list was changed (so it knows to save it)
    props.setAttribListWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The 'unit' has changed for an attribute.
  //-------------------------------------------------------------------------------
  function OnUnitChanged(attribute: IVectorLayerAttributeEdited, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!props.newLayerAttribs || !attribute) return;

    const newValue: string = event.target.value;

    const updatedAttribute: IVectorLayerAttributeEdited = 
    {
      ...attribute,
      unit: newValue,
      edited: true
    }

    const updatedAttribList: IVectorLayerAttributeEdited[] = props.newLayerAttribs.map(entry => entry.attribute_id === attribute.attribute_id ? updatedAttribute : entry);

    // Update the attribute list
    props.setNewLayerAttribs(updatedAttribList);

    // Tell calling code that the attribute list was changed (so it knows to save it)
    props.setAttribListWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The "Can Add" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnCanAddCheckBoxChange = (attribute: IVectorLayerAttributeEdited, event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!props.newLayerAttribs || !attribute) return;

    const updatedAttribute: IVectorLayerAttributeEdited = 
    {
      ...attribute,
      can_add: checked,
      edited: true
    }

    const updatedAttribList: IVectorLayerAttributeEdited[] = props.newLayerAttribs.map(entry => entry.attribute_id === attribute.attribute_id ? updatedAttribute : entry);

    // Update the attribute list
    props.setNewLayerAttribs(updatedAttribList);

    // Tell calling code that the attribute list was changed (so it knows to save it)
    props.setAttribListWasChanged(true);
  }












  




  if(!props.layer) return null;

  if(props.layer.geoserverSourceType === 'raster') return (
    <Typography sx={{ mt: 3, fontSize: '1.5rem', color: theme_errorRed, textAlign: 'center', opacity: 0.7 }}>
      This is a raster layer (no attributes).
    </Typography>
  )

  if(!props.layer.attributes || props.layer.attributes.length === 0) return (
    <Typography sx={{ mt: 3, fontSize: '1.5rem', color: theme_textColorBlended, textAlign: 'center', opacity: 0.7 }}>
      This layer has no attributes.
    </Typography>
  )

  if(!props.newLayerAttribs) return null;

  // Main render

  return (

    <Stack>

      <Typography sx={{ fontSize: '1rem', color: theme_textColorBlended, mb: 1.5 }}>
        This layer has {props.newLayerAttribs.length} attributes ({GetVisibleAttributeCount()} visible).
      </Typography>

      {props.newLayerAttribs.map(attrib =>

        attrib.attribute_name !== 'geom' && attrib.attribute_name !== 'geometry' && attrib.attribute_name !== 'shape'
          ?
            <Stack key={attrib.attribute_name} direction='row' sx={{ my: 0.7, alignItems: 'start' }}>

              <Tooltip title='Toggle the visibility of this attribute' arrow placement='left'>
                <IconButton sx={{ m:0, p:0.5, opacity: attrib.is_visible ? 1 : 0.4   }} 
                            onClick={(_)=>OnToggleIsVisible(attrib)}>
                  <VisibilityIcon sx={{ color: theme_textColorBlended }}/>
                </IconButton>
              </Tooltip>

              <Stack direction='column' sx={{ ml: 2, width: '100%', opacity: attrib.is_visible ? 1 : 0.4 }}>

                {attribBeingEdited?.attribute_id === attrib.attribute_id 
                  ?
                    // EDIT mode

                    <Stack sx={{ bgcolor: theme_textColorMain+'20', borderRadius: 2, p:1 }}>

                       <Typography sx={{ color: theme_textColorBlended, opacity: 0.7, fontWeight: 'bold', fontSize: '1rem'  }}>
                         {attrib.attribute_name} ({attrib.data_type})
                       </Typography>

                      <Stack direction='row' sx={{ mt: 2 }}>

                        {/* Attribute display name */}

                        <CustomTextField variant='standard' size='small' 
                                          value={attrib.display_name} onChange={e=>OnDisplayNameChanged(attrib,e)}
                                          autoComplete='off' inputProps={{ maxLength: ATTRIB_DISPLAY_NAME_MAX_LENGTH }}
                                          label=
                                          {
                                            <Stack direction='row' sx={{ alignItems: 'center' }}>
                                              <Typography sx={{ color: theme_textColorBlended }}>
                                                Name   
                                              </Typography>
                                              <Typography sx={{ ml: 1, color: theme_errorRed, opacity: 0.8, fontSize: '0.8rem' }}>
                                                (required)
                                              </Typography>
                                            </Stack>
                                          }
                                          sx={{ p: 0, width: '100%' }}/>

                        {/* Unit */}

                        <CustomTextField variant='standard' size='small' autoComplete='off'
                                        value={attrib.unit} onChange={e=>OnUnitChanged(attrib,e)}
                                        inputProps={{ maxLength: ATTRIB_UNIT_MAX_LENGTH }}
                                        label={<Typography sx={{ color: theme_textColorBlended }}>Unit</Typography>}
                                        sx={{ ml: 5, p:0, width: '200px' }}/>

                      </Stack>

                      {/* Description */}

                      <CustomTextField variant='standard' size='small' autoComplete='off'
                                      value={attrib.description} onChange={e=>OnDescriptionChanged(attrib,e)}
                                      inputProps={{ maxLength: ATTRIB_DESCRIPTION_MAX_LENGTH }}
                                      label={<Typography sx={{ color: theme_textColorBlended }}>Description</Typography>}
                                      sx={{ p:0, mt: 1 }}/>

                      {/* Info URL */}

                      <CustomTextField variant='standard' size='small'autoComplete='off'
                                      value={attrib.info_url} onChange={e=>OnInfoURLChanged(attrib,e)}
                                      inputProps={{ maxLength: ATTRIB_INFO_URL_MAX_LENGTH }}
                                      label={<Typography sx={{ color: theme_textColorBlended }}>Link to additional info (URL)</Typography>}
                                      sx={{ p:0, mt: 1 }}/>

                      {/* "Can Add" checkbox */}

                      {attrib.data_type === 'number'
                        ?
                          <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'left', mt: 2 }}>
                            <Checkbox size='small' checked={attrib.can_add===true}
                                      onChange={(e,c)=>OnCanAddCheckBoxChange(attrib,e,c)}
                                      sx=
                                      {{ 
                                        ml: '-10px', mr: 0, color: theme_textColorBlended, width: '40px', height: '40px',
                                        '&.Mui-checked': { color: theme_textColorBlended } 
                                      }} />
                              <Stack>
                                <Typography sx={{ color: theme_textColorMain+'D0', fontSize: '0.9rem' }}>
                                  This is a numerical value that can be added together (ex: acres, dollars).
                                </Typography>
                                <Typography sx={{ color: theme_textColorBlended, opacity: 0.8, fontSize: '0.7rem' }}>
                                  Uncheck this box for attributes that contain things like id numbers, codes, percentages, etc.
                                </Typography>
                              </Stack>
                          </Stack>
                        :null
                      }

                    </Stack>
                  :
                    // VIEW mode: show the description (if there is one)

                    <Stack direction='column'>

                      <Stack direction='row' sx={{ alignItems: 'center' }}>

                        {attrib.attribute_name === attrib.display_name
                          ?
                            <Typography sx={{ color: theme_orange, opacity: 0.8, fontSize: '1rem'  }}>
                              {attrib.attribute_name}
                            </Typography>
                          :
                            <Stack direction='row' sx={{ alignItems: 'center' }}>
                              <Typography sx={{ color: theme_orange, opacity: 0.8, fontSize: '1rem'  }}>
                                {attrib.display_name}
                              </Typography>
                                <Typography sx={{ ml: 2, color: theme_textColorBlended, opacity: 0.7, fontSize: '0.8rem'  }}>
                                {attrib.attribute_name}
                              </Typography>
                            </Stack>
                        }

                        <Typography sx={{ ml: 1, color: theme_textColorBlended, opacity: 0.5, fontSize: '0.8rem'  }}>
                          ({attrib.data_type})
                        </Typography>

                      </Stack>

                      {attrib.description && attrib.description.length > 0
                        ?
                          <Typography sx={{ ml: 0, color: theme_textColorMain, opacity: 0.6, fontSize: '0.9rem',
                                            overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                            {attrib.description}
                          </Typography>
                        :
                          <Typography sx={{ color: theme_textColorMain, opacity: 0.4, fontSize: '0.8rem'  }}>
                            No description.
                          </Typography>
                      }
                    </Stack>
                }

              </Stack>

              <Tooltip title={attribBeingEdited?.attribute_id === attrib.attribute_id ?  'Stop editing this attribute' : 'Edit this attribute'} arrow placement='right'>
                <IconButton onClick={(_)=>OnEditAttribute(attrib)}>
                  <EditIcon sx={{ color: attribBeingEdited?.attribute_id === attrib.attribute_id ? theme_orange : theme_textColorBlended }}/>
                </IconButton>
              </Tooltip>

            </Stack>
          :null
      )}

    </Stack>
  )
}






//-------------------------------------------------------------------------------
// Validate the attribute list.
// Returns an error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
export function ValidateLayerAttributes(attributes: IVectorLayerAttributeEdited[]|undefined): string
{
  if(!attributes) return 'Invalid layer attribute list';

  for(let i=0; i < attributes.length; i++)
  {
    // We only care about attributes that were edited by the user
    if(attributes[i].edited !== true)
      continue;

    if(attributes[i].display_name.trim().length === 0)
      return `Attribute "${attributes[i].attribute_name}" cannot have an empty name`;
  }

  // Validated
  return '';
}

