// HBV operations

import useStore from "../store";
import { IAoi } from "../Aois/AoiInterfaces";
import { CallServer } from "../CallServer";
import Debug from "../Debug";
import { ILayer } from "../Layers/LayerInterfaces";
import { AddLayerToMap, LoadLayerDataFromServerIntoILayer, RemoveLayerFromMap } from "../Layers/LayerOps";
import { ToastNotification } from "../ToastNotifications";
import { IHbv, IHbvImpact, IHbvNRRGroup, IHbvScenario } from "./HbvInterfaces";
import { PaddingOptions } from "mapbox-gl";
import { HBV_PANEL_WIDTH_PIXELS } from "./HbvPanel";
import { DetectAoiErrors, LoadAoi } from "../Aois/AoiOps";
import { IProject, IProjectScenario } from "../Projects/ProjectInterfaces";
import { FriendlyDate, FriendlyDateFromStr } from "../Globals";
import { SaveActiveProject } from "../Projects/ProjectOps";
import { GetChartColor } from './HbvChartColors';
import { GetNrrName } from "../Bundles/BundleOps";


//-------------------------------------------------------------------------------
// Run HBV.
//-------------------------------------------------------------------------------
export async function RunHBV(aoi: IAoi | null): Promise<boolean>
{
  if(!aoi)
  {
    Debug.error('HbvOps.RunHBV> NULL aoi');
    return false;
  }

  const errorMessage: string | null = DetectAoiErrors(aoi);
  if(errorMessage)
  {
    ToastNotification('warning',  errorMessage);
    return false;
  }

  if(aoi.isDirty)
  {
    ToastNotification('warning',  'The active AOI is not saved.  Please save it first before you run HBV.');
    return false;
    //await SaveActiveAoi();
  }

  const store_project = useStore.getState().store_project;
  if(!store_project)
  {
    Debug.error('HbvOps.RunHBV> No active project');
    return false;
  }

  if(!useStore.getState().store_bundle)
  {
    ToastNotification('warning',  'Please select a bundle first.');
    return false;
  }

  // Call the server to get the data

  const server = new CallServer();
  server.Add("aoi_id", aoi.aoi_id);
  if(store_project.project_id)
    server.Add("project_id ", store_project.project_id);
  
  let nrr_impacts = [];
  const nrrStates = store_project.user_settings.nrrStates;
  for(let i=0; i < nrrStates.length; i++)
    if(nrrStates[i].enabled)
      for(let j=0; j < nrrStates[i].impactStates.length; j++)
        if(nrrStates[i].impactStates[j].enabled)
          nrr_impacts.push({ "nrr_id": nrrStates[i].nrr_id, "impact_id": nrrStates[i].impactStates[j].nrr_impact_id });

  if(nrr_impacts.length === 0)
  {
    ToastNotification('info', 'All NRRs and impacts are currently turned off');
    return false;
  }

  server.Add("nrr_impacts", nrr_impacts)

  server.Add("srid", "4326");  // (optional) this is so the map_extent data is lat/lng usable by Mapbox

  // Generate a unique and random "run instance ID" to identify this HBV 
  // run (used for Cancel feature), and save it in the state store before
  // we start the server call.
  const hbvRunInstanceID = Math.random();
  useStore.getState().store_setHbvRunInstanceID(hbvRunInstanceID);

  useStore.getState().store_setHbvIsRunning(true);  // Tell the UI we are calculating HBV

  const result = await server.Call('post', '/hbv');

  // Now that the HBV run has finished, check if the currently-stored "instance run ID" 
  // still matches the one saved in the state store (used for Cancel feature).
  if(useStore.getState().store_hbvRunInstanceID !== hbvRunInstanceID)
  {
    // This HBV run's instance ID does NOT match what is currently in the state 
    // store.  That means either the user CANCELED or started a different run.
    // In either case, we simply ignore the returned data and exit.
    Debug.warn('HbvOps.RunHBV> Ignoring returned HBV data (assuming user canceled)');
    return true;
  }

  // Now that this run is done successfully, we no longer need the HBV run instance ID
  useStore.getState().store_setHbvRunInstanceID(null);

  useStore.getState().store_setHbvIsRunning(false); // Tell the UI we are no longer calculating HBV

  if(result.success)
  {
    Debug.log('HbvOps.RunHBV> API server call SUCCESS');
    //Debug.log('HbvOps.RunHBV> SUCCESS! data=' + JSON.stringify(result.data));

    const hbv_data: IHbv | null = result.data;
    if(!hbv_data)
    {
      ToastNotification('error', "Unable to retrieve HBV data")
      Debug.error('HbvOps.RunHBV> Received invalid hbv data');
      return false;
    }

    // Create a new scenario for this HBV data
    const hbvScenario: IHbvScenario = 
    {
      scenario_id: null,    // no ID, since it's a new unsaved scenario
      name: '',
      aoi_id: aoi.aoi_id,
      aoi_name: aoi.aoi_name,
      aoi_acres: aoi.acres,
      last_run_date: FriendlyDate(new Date()),
      hbv_data: hbv_data
    }

    // Perform post-load processing of the scenario (sorting, assign colors, etc)
    ProcessScenarioAfterLoading(hbvScenario);

    // Update the state store
    useStore.getState().store_setHbvScenario(hbvScenario);

    // Process and load the HBV data into the UI
    LoadHbvLayersIntoMap(hbvScenario);

    Debug.log(`HBV.RunHBV> HBV generated for AOI id ${aoi.aoi_id}.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to generate HBV data")
    Debug.error(`HBV.RunHBV> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Processes the HBV data (ie loads it into the UI).
//-------------------------------------------------------------------------------
function LoadHbvLayersIntoMap(hbvScenario: IHbvScenario)
{
  // Load and process the HBV main layer

  hbvScenario.hbv_data.layer = ProcessHBVLayer(hbvScenario, hbvScenario.hbv_data.lmp_layer)
  if(!hbvScenario.hbv_data.layer)
    return false;

  hbvScenario.hbv_data.layer.enabled = true;
  hbvScenario.hbv_data.layer.friendlyName = "HBV: " + hbvScenario.hbv_data.layer.friendlyName;
  useStore.getState().store_addOrUpdateLayer(hbvScenario.hbv_data.layer);

  // Load and process each of the impact layers (inside the'break_out' key)

  for(let i=0; i < hbvScenario.hbv_data.break_out.length; i++)
  {
    const impact_layer: any = hbvScenario.hbv_data.break_out[i].impact_layer;
    if(!impact_layer) continue; // some impacts may not have display layers

    if(hbvScenario.hbv_data.break_out[i].dollar <= 0 || hbvScenario.hbv_data.break_out[i].acres <= 0)
      continue; // ignore impact layers with 0 value or 0 acres

    const newLayer: ILayer | undefined = ProcessHBVLayer(hbvScenario, impact_layer);
    if(!newLayer)
      return false;
    newLayer.friendlyName = "HBV IMPACT: " + newLayer.friendlyName;

    hbvScenario.hbv_data.break_out[i].layer = newLayer;
    useStore.getState().store_addOrUpdateLayer(newLayer);
  }

  // Only show the 3D style option if the max is strictly bigger than the min
  // if(hbv.layer.defaultProp)
  //   setEnable3DStyle(hbv.layer.defaultProp.max > hbv.layer.defaultProp.min);

  // Add the HBV layer to the map
  AddLayerToMap(hbvScenario.hbv_data.layer.id);

  // Open the HBV side-panel
  //useStore.getState().store_setHbvPanelIsOpen(true);

  // Close the drawer menu so there is more room and less clutter
  //store_setDrawerOpen(false);

  const store_map = useStore.getState().store_map;
  if(!store_map) return false;

  // Figure out the extent of the AOI
  // NOTE:  For now we'll use the active AOI's geometry to figure out the HBV's extent,
  //        but in the future we'll support multi-AOI HBVs, so that won't work.  It would
  //        be better if the HBV API returned the extent.
  // const geom = aoi.updatedGeom ? aoi.updatedGeom : aoi.geom;
  // const bbox = turf.bbox(geom); // It should already be in WSG84/EPSG:4326 (lat/long)
  // [minX, minY, maxX, maxY]
  // [NE-LNG, NE-LAT, SW-LNG, SW-LAT]
  //store_map.fitBounds([bbox[2], bbox[3], bbox[0], bbox[1]], {padding: 50});

  // Navigate the map to the HBV area | SW-LNG, SW-LAT, NE-LNG, NE-LAT
  if(hbvScenario.hbv_data.layer.map_extent)
  {
    const NE_lng = hbvScenario.hbv_data.layer.map_extent[2];
    const NE_lat = hbvScenario.hbv_data.layer.map_extent[3];
    const SW_lng = hbvScenario.hbv_data.layer.map_extent[0];
    const SW_lat = hbvScenario.hbv_data.layer.map_extent[1];

    // NOTE: Latitude range -90 and +90 degrees
    //       Longitude range -180 and +180 degrees

    if(NE_lng >= -180 && NE_lng <= 180 && SW_lng >= -180 && SW_lng <= 180 &&
       NE_lat >= -90 && NE_lat <= 90 && SW_lat >= -90 && SW_lat <= 90)
    {
      const hbvPadding: PaddingOptions = 
      {
        top: 100,
        bottom: 100,
        left: 100 + HBV_PANEL_WIDTH_PIXELS,
        right: 100,
      }
      store_map.fitBounds([NE_lng, NE_lat, SW_lng, SW_lat], { padding: hbvPadding });
    }
    else  // Invalid lat/lng coords
    {
      Debug.error('HbvOps.LoadHbvLayersIntoMap> Invalid map extent - one or all of the lat/lng coords is out of range');
    }
  }
  
  store_map.setBearing(0);
  store_map.setPitch(0);
} 

//-------------------------------------------------------------------------------
// Load an HBV display layer (can be the main HBV layer, or an HBV impact layer).
//-------------------------------------------------------------------------------
function ProcessHBVLayer(hbvScenario: IHbvScenario, hbvRawLayer: any): ILayer | undefined
{
  if(!hbvRawLayer) return undefined;

  // Load the data into into an ILayer

  const layer: ILayer | null = LoadLayerDataFromServerIntoILayer(hbvRawLayer, true);
  if(!layer)
  {
    ToastNotification('error', "Unable to load in HBV layer data")
    Debug.error('HbvOps.ProcessHBVLayer> Unable to load hbv layer data into ILayer');
    return undefined;
  }

  layer.isHBVLayer = true;
  layer.EditCustomLayer = false;
  layer.DeleteCustomLayer = false;

  // Default paint/style
  layer.mapboxLayerType = 'fill';

  if(hbvScenario.hbv_data.hbv <= 0)
    layer.mapboxPaint = 
    {
      'fill-color': '#303030',
    }
  else
    layer.mapboxPaint = GetHbvPaint_2D(layer);

  // {
  //   'line-color': '#80cdc1',
  //   'line-width': 1,
  //   'line-opacity': 0.7,
  // }

  // let defaultProp: ILayerProp | null = GetDefaultLayerProp(layer);
  // if(!defaultProp)
  // {
  //   ToastNotification('error', "Unable to load HBV data")
  //   Debug.error('HBV.ProcessHBVLayer> Missing or invalid props');
  //   return null;
  // }

  // Only show the 3D style option if the max is strictly bigger than the min
  //setEnable3DStyle(defaultProp.max > defaultProp.min);


  // If we have data for a prop, create a simple legend
  if(layer.defaultProp)
  {
    // Legend (converted from $/30x30m to $/acre)
    const range =  layer.defaultProp.max - layer.defaultProp.min;

    // If the min and max are the same, make a one-item legend
    if(layer.defaultProp.min === layer.defaultProp.max)
      layer.legend = 
      {
        entries:
        [
          { id: 3, name: `$${Math.round(layer.defaultProp.max)}`, color: '#4dac26' },
        ]
      }
    else
      layer.legend = 
      {
        entries:
        [
          // { id: 1, name: `$${Math.round(hbv.min/CONVERT_30x30M_TO_ACRE)}/acre`, color: '#a6611a' },
          // { id: 2, name: `$${Math.round((hbv.min + 1/2*range/CONVERT_30x30M_TO_ACRE))}/acre`, color: '#7a8720' },
          // { id: 3, name: `$${Math.round(hbv.max/CONVERT_30x30M_TO_ACRE)}/acre`, color: '#4dac26' },
          { id: 1, name: `$${Math.round(layer.defaultProp.min)}`, color: '#a6611a' },
          { id: 2, name: `$${Math.round((layer.defaultProp.min + 1/2*range))}`, color: '#7a8720' },
          { id: 3, name: `$${Math.round(layer.defaultProp.max)}`, color: '#4dac26' },
        ]
      }

    // if(defaultProp.min === 0)
    //   hbv.layer.legendEntries.push({ id: 0, name: `$0`, color: '#000000' });
  }

  layer.aoi_id_filters.push(hbvScenario.aoi_id); // this is used by the identify feature to filter using only this aoi (not all hbv aois)

  // layer.description = 
  // {
  //   details: `Highest and Best Value for '${aoi.aoi_name}'`
  // }

  // Add it as an "active" layer, so that it will appear in the active layers UI
  layer.activeInProject = true; 

  // Success
  return layer;
}


//-------------------------------------------------------------------------------
// Reset all HBV-related states.
//-------------------------------------------------------------------------------
export function ResetHbv()
{
  // If there is an active HBV scenario, clear it (from both the map and state store)

  const store_hbvScenario = useStore.getState().store_hbvScenario;
  if(store_hbvScenario && store_hbvScenario.hbv_data && store_hbvScenario.hbv_data.layer)
  {
    // Remove the main HBV layer

    RemoveLayerFromMap(store_hbvScenario.hbv_data.layer.id);
    useStore.getState().store_removeLayer(store_hbvScenario.hbv_data.layer.id);

    // Remove all the HBV impact layers

    for(let i=0; i < store_hbvScenario.hbv_data.break_out.length; i++)
    {
      const layer = store_hbvScenario.hbv_data.break_out[i].layer;
      if(!layer) continue;

      RemoveLayerFromMap(layer.id);
      useStore.getState().store_removeLayer(layer.id);
    }
  }

  useStore.getState().store_setHbvScenario(null);

  // If a scenario comparison is active, clear it
  useStore.getState().store_setHbvComparedScenarios([]);
}

//-------------------------------------------------------------------------------
// HBV 2D paint.
//-------------------------------------------------------------------------------
export function GetHbvPaint_2D(layer: ILayer | null)
{
  if(!layer) return;

  if(!layer.defaultProp)
  {
    Debug.error('HbvOps.GetHbvPaint_2D> Missing or invalid props');
    return false;
  }

  if(layer.defaultProp.min === 0)
    layer.defaultProp.min = 0.0000001;

  
  const range =  layer.defaultProp.max - layer.defaultProp.min;
  let paint = {};

  // If the min and max are the same, use 0 and max
  if(layer.defaultProp.min === layer.defaultProp.max)
    paint =
    {
      'fill-outline-color': 'rgba(0,0,0,0)',
      'fill-color':
      {
        property: layer.defaultProp.name, // this is what the color is based on
        stops: 
        [
          [0, '#303030'],
          [layer.defaultProp.max, '#4dac26'], // green
        ]
      },
      'fill-opacity': 1,
    }
  else
    paint =
    {
      'fill-outline-color': 'rgba(0,0,0,0)',
      'fill-color':
      {
        property: layer.defaultProp.name, // this is what the color is based on
        stops: 
        [
          [0, '#303030'],
          [layer.defaultProp.min,'#a6611a'],    // brown-green
          [layer.defaultProp.min + 1/2*range,'#7a8720'],
          [layer.defaultProp.max, '#4dac26'],
        ]
      },
      'fill-opacity': 1,
    }

  return paint;
}


//-------------------------------------------------------------------------------
// HBV 3D paint.
//-------------------------------------------------------------------------------
export function GetHbvPaint_3D(layer: ILayer | null)
{
  if(!layer) return;

  if(!layer.defaultProp)
  {
    Debug.error('HbvOps.GetHbvPaint_3D> Missing or invalid props');
    return false;
  }

  let min = layer.defaultProp.min;
  let max = layer.defaultProp.max;

  // If the min is 0, add a tiny amount since we already explicitely cover value 0 in the style.
  if(min === 0)
    min += 0.000000001;

  // If the min and max are the same, Mapbox isn't happy - fudge the value 
  // slightly so they  are no longer the same.
  if(min === max)
    max += 0.000000001;

  const range =  max - min;
  let paint = {};

  // If the min and max are the same, use 0 and max
  if(layer.defaultProp.min === layer.defaultProp.max)
    paint =
    {
      "fill-extrusion-color": 
      [
        'interpolate',
        ['linear'],
        ['get', layer.defaultProp.name],

        0, '#303030',     // dark gray
        max, '#4dac26'  // max - green

      ],
      'fill-extrusion-height': ['get', layer.defaultProp.name],
      'fill-extrusion-base': 0,
      'fill-extrusion-opacity': 1,
      'fill-extrusion-vertical-gradient': true,
    }
  else
    paint =
    {
      "fill-extrusion-color": 
      [
        'interpolate',
        ['linear'],
        ['get', layer.defaultProp.name],

        0, '#303030',     // dark gray
        min,'#a6611a',    // min - brown
        min + 1/2*range,'#7a8720', // mid - dark yellow
        max, '#4dac26'  // max - green

      ],
      'fill-extrusion-height': ['get', layer.defaultProp.name],
      'fill-extrusion-base': 0,
      'fill-extrusion-opacity': 1,
      'fill-extrusion-vertical-gradient': true,
    }

  return paint;
}

//-------------------------------------------------------------------------------
// Save the active HBV results as a scenario.
//-------------------------------------------------------------------------------
export async function SaveHbvResultsToScenario(newScenarioName: string, hbvScenario: IHbvScenario): Promise<boolean>
{
  const store_project: IProject | null = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.scenario_group_id)
  {
    Debug.warn('HbvOps.SaveHbvResultsToScenario> Invalid project, project_id, or scenario_group_id');
    return false;
  }

  const store_aoi: IAoi | null = useStore.getState().store_aoi;
  if(!store_aoi)
  {
    Debug.warn('HbvOps.SaveHbvResultsToScenario> Invalid aoi');
    return false;
  }

  // The scenario data has the processed 'layer' items (one for the main hbv, and one for
  // each impact).  We don't want these to be saved as part of the scenario.
  //
  // So we make a copy, and delete the 'layer' items from it before the save.

  const fixedHbvData: IHbv = JSON.parse(JSON.stringify(hbvScenario.hbv_data));
  delete fixedHbvData.layer;
  for(let i=0; i < fixedHbvData.break_out.length; i++)
    delete fixedHbvData.break_out[i].layer;

  // Call the server

  const server = new CallServer();
  server.Add("name", newScenarioName);
  server.Add("aoi_id", hbvScenario.aoi_id);
  server.Add("scenario_group_id", store_project.scenario_group_id);
  server.Add("hbv_data", JSON.stringify(fixedHbvData));

  let nrr_impacts = [];
  const nrrStates = store_project.user_settings.nrrStates;
  for(let i=0; i < nrrStates.length; i++)
    if(nrrStates[i].enabled)
      for(let j=0; j < nrrStates[i].impactStates.length; j++)
        if(nrrStates[i].impactStates[j].enabled)
          nrr_impacts.push({ "nrr_id": nrrStates[i].nrr_id, "impact_id": nrrStates[i].impactStates[j].nrr_impact_id });

  server.Add("impacts", nrr_impacts);

  useStore.getState().store_setHbvScenarioIsSaving(true);  // Tell the UI we are saving

  const result = await server.Call('post', '/scenario');

  useStore.getState().store_setHbvScenarioIsSaving(false); // Tell the UI we are done saving

  if(result.success)
  {
    Debug.log('HbvOps.SaveHbvResultsToScenario> API server call SUCCESS');
    //Debug.log('HbvOps.SaveHbvResultsToScenario> SUCCESS! data=' + JSON.stringify(result.data));

    const hbv_scenario_id: number = result.data.hbv_scenario_id;
    if(!hbv_scenario_id || hbv_scenario_id <= 0)
    {
      ToastNotification('error', "Unable to save HBV results to a scenario");
      Debug.error(`HbvOps.SaveHbvResultsToScenario> Received invalid hbv_scenario_id (${hbv_scenario_id})`);
      return false;
    }

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    // Update the state store

    // Add this new scenario to the active project

    const newProjectHbvScenario: IProjectScenario =
    {
      hbv_scenario_id: hbv_scenario_id,
      name: newScenarioName,
      last_run_date: FriendlyDate(new Date()),
      dollars: hbvScenario.hbv_data.hbv,
      aoi_id: hbvScenario.aoi_id,
      aoi_name: store_aoi.aoi_name, // what if the aoi/aoi name changes after hbv is ran and before the save?
      selected: false,
    }

    useStore.getState().store_addProjectHbvScenario(newProjectHbvScenario);
    //useStore.getState().store_setProjectIsDirty(true);
    useStore.getState().store_setHbvScenarioID(hbv_scenario_id);
    useStore.getState().store_setHbvScenarioName(newScenarioName);

    // Force-save the active project
    SaveActiveProject();

    Debug.log(`HbvOps.SaveHbvResultsToScenario> HBV results saved to a new scenario (hbv_scenario_id=${hbv_scenario_id})`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to save HBV results to a scenario")
    Debug.error(`HbvOps.SaveHbvResultsToScenario> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Load the specified HBV scenario.
//-------------------------------------------------------------------------------
export async function LoadHbvScenario(scenarioListItem: IProjectScenario): Promise<boolean>
{
  // If this scenario is not for the active AOI, first load in the scenario's AOI

  if(scenarioListItem.aoi_id !== useStore.getState().store_aoi?.aoi_id)
  {
    const result: boolean = await LoadAoi(scenarioListItem.aoi_id);
    if(!result)
      ToastNotification('error', 'Unable to load scenario\'s AOI');
  }

  // Call the server

  const server = new CallServer();
  server.Add("hbv_scenario_id", scenarioListItem.hbv_scenario_id);

  useStore.getState().store_setHbvScenarioIsLoading(true);  // Tell the UI we are doing server work

  const result = await server.Call('get', '/scenario');

  useStore.getState().store_setHbvScenarioIsLoading(false); // Tell the UI we are done doing server work

  if(result.success)
  {
    Debug.log('HbvOps.LoadHbvScenario> API server call SUCCESS');
    //Debug.log('HbvOps.LoadHbvScenario> SUCCESS! data=' + JSON.stringify(result.data));

    const hbvScenario: IHbvScenario = result.data;
    if(!hbvScenario)
    {
      ToastNotification('error', "Unable to load HBV scenario");
      Debug.error(`HbvOps.LoadHbvScenario> Received invalid scenario data`);
      return false;
    }

    hbvScenario.scenario_id = scenarioListItem.hbv_scenario_id;

    // Perform post-load processing of the scenario (sorting, assign colors, etc)
    ProcessScenarioAfterLoading(hbvScenario);

    // Update the state store
    useStore.getState().store_setHbvScenario(hbvScenario);

    // Process and load the HBV data into the UI
    LoadHbvLayersIntoMap(hbvScenario);

    Debug.log(`HbvOps.LoadHbvScenario> HBV Scenario loaded (hbv_scenario_id=${scenarioListItem.hbv_scenario_id})`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load HBV scenario")
    Debug.error(`HbvOps.LoadHbvScenario> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete the specified HBV scenario.
// 
// NOTE:  If the scenario is also part of another project (was shared etc), then it
//        is simply removed from the specified project.  If the scenario ONLY exists 
//        in the specified project, it is permanently deleted.
//-------------------------------------------------------------------------------
export async function DeleteHbvScenario(hbv_scenario_id: number, scenario_group_id: number): Promise<boolean>
{
  // Call the server

  const server = new CallServer();
  server.Add("hbv_scenario_id", hbv_scenario_id);
  server.Add("scenario_group_id", scenario_group_id);

  //useStore.getState().store_setHbvScenarioIsDeleting(true);  // Tell the UI we are doing server work

  const result = await server.Call('delete', '/scenario');

  //useStore.getState().store_setHbvScenarioIsDeleting(false); // Tell the UI we are done doing server work

  if(result.success)
  {
    Debug.log('HbvOps.DeleteHbvScenario> API server call SUCCESS');
    //Debug.log('HbvOps.DeleteHbvScenario> SUCCESS! data=' + JSON.stringify(result.data));

    // Update the state store

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    // Remove the scenario from the project's hbv scenarios list (and mark the project as dirty)
    useStore.getState().store_removeProjectHbvScenario(hbv_scenario_id);
    //useStore.getState().store_setProjectIsDirty(true);

    // If we've deleted the active hbv scenario, clear out the UI
    const store_hbvScenario = useStore.getState().store_hbvScenario;
    if(store_hbvScenario && hbv_scenario_id === store_hbvScenario.scenario_id)
      ResetHbv();

    ToastNotification('success', 'The HBV scenario has been deleted');

    Debug.log(`HbvOps.DeleteHbvScenario> HBV Scenario deleted (hbv_scenario_id=${hbv_scenario_id})`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete HBV scenario")
    Debug.error(`HbvOps.DeleteHbvScenario> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Rename the specified HBV scenario.
//-------------------------------------------------------------------------------
export async function RenameHbvScenario(scenario_group_id: number, hbv_scenario_id: number, new_hbv_scenario_name: string): Promise<boolean>
{
  // Call the server

  const server = new CallServer();
  server.Add("scenario_group_id ", scenario_group_id);
  server.Add("hbv_scenario_id", hbv_scenario_id);
  server.Add("name", new_hbv_scenario_name.trim());

  //useStore.getState().store_setHbvScenarioIsRenaming(true);  // Tell the UI we are doing server work

  const result = await server.Call('put', '/scenario');

  //useStore.getState().store_setHbvScenarioIsRenaming(false); // Tell the UI we are done doing server work

  if(result.success)
  {
    Debug.log('HbvOps.RenameHbvScenario> API server call SUCCESS');
    //Debug.log('HbvOps.RenameHbvScenario> SUCCESS! data=' + JSON.stringify(result.data));

    // For shared project sync, any time we make changes to AOIS or scenarios we get an
    // updated server-side date, and we use that as the new "project load date" so that 
    // a sync is not triggered based on the user's own changes.
    if(result.data && result.data.date_time)
      useStore.getState().store_setProjectServerSideLoadDate(result.data.date_time);

    Debug.log(`HbvOps.RenameHbvScenario> HBV Scenario renamed (hbv_scenario_id=${hbv_scenario_id})`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to rename HBV scenario")
    Debug.error(`HbvOps.RenameHbvScenario> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Compare a list of HBV scenarios.
//-------------------------------------------------------------------------------
export async function CompareHbvScenarios(hbv_scenario_ids: number[]): Promise<boolean>
{
  // Call the server

  const server = new CallServer();
  server.Add("hbv_scenario_ids", hbv_scenario_ids);

  useStore.getState().store_setHbvScenarioCompareIsLoading(true);  // Tell the UI we are doing server work

  const result = await server.Call('post', '/compare_scenarios');

  useStore.getState().store_setHbvScenarioCompareIsLoading(false); // Tell the UI we are done doing server work

  if(result.success)
  {
    Debug.log('HbvOps.CompareHbvScenarios> API server call SUCCESS');
    //Debug.log('HbvOps.CompareHbvScenarios> SUCCESS! data=' + JSON.stringify(result.data));

    let scenarios: IHbvScenario[] = result.data;
    if(!scenarios)
    {
      ToastNotification('error', "Unable to compare HBV scenarios");
      Debug.error(`HbvOps.CompareHbvScenarios> Received invalid data`);
      return false;
    }

    // Sort the scenarios by total dollar value (we want them in order in the compare UI)
    scenarios = scenarios.sort((a: IHbvScenario, b: IHbvScenario) => b.hbv_data.hbv - a.hbv_data.hbv);

    // Perform post-load processing of each scenario (sorting, assign colors, etc)
    for(let i=0; i < scenarios.length; i++)
      ProcessScenarioAfterLoading(scenarios[i]);

    // Find the max dollar value among the compared scenarios

    let maxDollarValue = -1;
    for(let i=0; i < scenarios.length; i++)
      if(scenarios[i].hbv_data.hbv > maxDollarValue)
        maxDollarValue = scenarios[i].hbv_data.hbv;

    // For each scenario, tell it the max dollar value across all compared scenarios.
    // This will be used by each scenario chart to resize itself based on the max dollar value.

    if(maxDollarValue !== -1)
      for(let i=0; i < scenarios.length; i++)
        scenarios[i].comparisonMaxDollarValue = maxDollarValue

    // Update the state store
    useStore.getState().store_setHbvComparedScenarios(scenarios);

    Debug.log(`HbvOps.CompareHbvScenarios> HBV scenario comparison data loaded.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to compare HBV scenarios")
    Debug.error(`HbvOps.CompareHbvScenarios> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Return the list of all scenarios for the active AOI (the project contains all
// scenarios for multiple AOIs).
//-------------------------------------------------------------------------------
// export function GetScenariosForActiveAOI(): IProjectHbvScenarioListItem[]
// {
//   const store_project = useStore.getState().store_project;
//   if(!store_project) return [];

//   const store_aoi = useStore.getState().store_aoi;
//   if(!store_aoi) return [];

//   const scenarios: IProjectHbvScenarioListItem[] = [];
//   for(let i=0; i < store_project.scenarios.length; i++)
//     if(store_project.scenarios[i].aoi_id === store_aoi.aoi_id)
//       scenarios.push(store_project.scenarios[i]);
      
//   return scenarios;
// }

//-------------------------------------------------------------------------------
// Return the list of all scenarios for all AOIs in the active project.
//-------------------------------------------------------------------------------
// export function GetScenariosForAllProjectAois(): IProjectHbvScenarioListItem[]
// {
//   const store_project = useStore.getState().store_project;
//   if(!store_project) return [];

//   const scenarios: IProjectHbvScenarioListItem[] = [];
//   for(let i=0; i < store_project.scenarios.length; i++)
//       scenarios.push(store_project.scenarios[i]);
      
//   return scenarios;
// }

//-------------------------------------------------------------------------------
// Returns the max height for the impacts panel for of all the scenarios being 
// compared.  For example, scenario 1 could have 1 impact, while scenario 2 has
// 7.  A max of 5 impacts can be displayed at once, so 5 "wins", and the height
// is based on that.
//-------------------------------------------------------------------------------
export function GetCompareScenariosImpactPanelHeight(): number
{
  const scenarios: IHbvScenario[] = useStore.getState().store_hbvComparedScenarios;

  let maxImpactCount = 0;
  for(let i=0; i < scenarios.length; i++)
  {
    // Count the number of non-zero-dollar impacts ($0 impacts are ignored)
    let impactCount = 0;
    for(let j=0; j < scenarios[i].hbv_data.break_out.length; j++)
      if(scenarios[i].hbv_data.break_out[j].dollar > 0)
        impactCount++;

    if(impactCount > maxImpactCount)
      maxImpactCount = impactCount;
  }

  // We display a max of 5 impacts before scrolling happens
  if(maxImpactCount > 5)
    maxImpactCount = 5;

  return maxImpactCount * 55; // we multiply by pixels used per impact to get the actual height
}
/*
//-------------------------------------------------------------------------------
// Re-run an HBV scenario - without getting the full new scenario data.
//-------------------------------------------------------------------------------
export async function ReRunHbvScenario_NoScenarioData(hbv_scenario_id: number, project_id: string): Promise<boolean>
{
  // Switch to the HBV drawer menu, as that will show progress while HBV is re-running
  useStore.getState().store_setActiveDrawerMenuItem('hbv');

  // Call the server

  const server = new CallServer();
  server.Add("hbv_scenario_id", hbv_scenario_id);
  server.Add("project_id", project_id);   // NOTE: Noarmally this shouldn't be needed for this operation, but the API requires it for now (not sure why)
  server.Add("srid", "4326");  // (optional) this is so the map_extent data is lat/lng usable by Mapbox

  useStore.getState().store_setHbvScenarioRefreshIsLoading(true);  // Tell the UI we are doing server work

  const result = await server.Call('post', '/rerun_scenario');

  useStore.getState().store_setHbvScenarioRefreshIsLoading(false); // Tell the UI we are done doing server work

  if(result.success)
  {
    Debug.log('HbvOps.ReRunHbvScenario_NoScenarioData> API server call SUCCESS');
    //Debug.log('HbvOps.ReRunHbvScenario_NoScenarioData> SUCCESS! data=' + JSON.stringify(result.data));

    const dollars: number = result.data.hbv;
    const last_run_date: string = FriendlyDateFromStr(result.data.last_run_date);

    // Update the state store
    useStore.getState().store_setProjectHbvScenarioInfo(hbv_scenario_id, dollars, last_run_date);

    Debug.log(`HbvOps.ReRunHbvScenario_NoScenarioData> HBV scenario has been re-run.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to refresh HBV scenario")
    Debug.error(`HbvOps.ReRunHbvScenario_NoScenarioData> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}
*/
//-------------------------------------------------------------------------------
// Re-run an HBV scenario - returns the full new scenario data.
//-------------------------------------------------------------------------------
export async function ReRunHbvScenario_GetScenarioData(hbv_scenario_id: number, scenario_group_id: number): Promise<boolean>
{
  // Switch to the HBV drawer menu, as that will show progress while HBV is re-running
  useStore.getState().store_setActiveDrawerMenuItem('hbv');

  // Call the server

  const server = new CallServer();
  server.Add("hbv_scenario_id", hbv_scenario_id);
  server.Add("scenario_group_id", scenario_group_id);
  server.Add("srid", "4326");  // (optional) this is so the map_extent data is lat/lng usable by Mapbox
  server.Add("return_scenario", 1);

  useStore.getState().store_setHbvScenarioRefreshIsLoading(true);  // Tell the UI we are doing server work

  const result = await server.Call('post', '/rerun_scenario');

  useStore.getState().store_setHbvScenarioRefreshIsLoading(false); // Tell the UI we are done doing server work

  if(result.success)
  {
    Debug.log('HbvOps.ReRunHbvScenario_GetScenarioData> API server call SUCCESS');
    //Debug.log('HbvOps.ReRunHbvScenario_GetScenarioData> SUCCESS! data=' + JSON.stringify(result.data));

    const scenario: IHbvScenario = result.data;
    scenario.scenario_id = hbv_scenario_id; // seems to be missing from the API call
    //scenario.last_run_date = FriendlyDate(new Date()); // the date coming from the API is missing a timezone, so it's UTC
    if(!scenario)
    {
      ToastNotification('error', "Unable to reffresh HBV scenario");
      Debug.error(`HbvOps.ReRunHbvScenario_GetScenarioData> Received invalid data`);
      return false;
    }

    // Perform post-load processing of the scenario (sorting, assign colors, etc)
    ProcessScenarioAfterLoading(scenario);

    // Sort the impacts of the scenario (by dollar descending)
    //scenario.hbv_data.break_out = scenario.hbv_data.break_out.sort((a: IHbvImpact, b: IHbvImpact) => b.dollar - a.dollar);

    // Update the state store
    useStore.getState().store_setProjectHbvScenarioInfo(hbv_scenario_id, scenario.hbv_data.hbv, FriendlyDateFromStr(scenario.last_run_date));
    useStore.getState().store_setHbvScenario(scenario);

    // Process and load the HBV data into the UI
    LoadHbvLayersIntoMap(scenario);

    Debug.log(`HbvOps.ReRunHbvScenario_GetScenarioData> HBV scenario has been re-run.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to refresh HBV scenario")
    Debug.error(`HbvOps.ReRunHbvScenario_GetScenarioData> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Performs a few post-loading operations on the specified HBV scenario.
//
// - sorts the list of impacts by dollars descending
// - assigns colors for each impact (used for the chart)
// - sets 'itemIndex' within each impact (this is used to linkup the impact tiles with the pie chart for mouse hover effect)
//-------------------------------------------------------------------------------
function ProcessScenarioAfterLoading(scenario: IHbvScenario)
{
  // Sort the all-impacts list (hbv_data.break_out) by dollar value (descending)
  scenario.hbv_data.break_out = scenario.hbv_data.break_out.sort((a: IHbvImpact, b: IHbvImpact) => b.dollar - a.dollar);

  // Assign an index based on the overall sort - this is used by the chart when mousing over a 
  // pie piece, so it knows the matching list index the matching impact tile

  let nextItemIndex: number = 0;

  for(let i=0; i < scenario.hbv_data.break_out.length; i++)
  {
    const impact: IHbvImpact = scenario.hbv_data.break_out[i];
    impact.itemIndex = nextItemIndex++;
  }

  // The all-impacts list (break_out) is used by the chart, but the info tiles below the chart 
  // need to be shown groupped by NRR.  So we will create 'nrrGroups' for that.

  // Group the break_out items by the NRRs they each belong to (the UI will display them that way)

  scenario.hbv_data.nrrGroups = [];

  for(let i=0; i < scenario.hbv_data.break_out.length; i++)
  {
    const impact: IHbvImpact = scenario.hbv_data.break_out[i];
    if(impact.dollar <= 0) continue; // if this is a zero-value hbv entry, ignore it

    if(!impact.nrr_id) continue;  // This should never happen

    let nrrGroup: IHbvNRRGroup | undefined = scenario.hbv_data.nrrGroups.find(nrrGroup => nrrGroup.nrr_id === impact.nrr_id);
    if(!nrrGroup)
    {
      // No group exists for this NRR - create a new one
      nrrGroup = 
      {
        nrr_id: impact.nrr_id,
        nrr_name: GetNrrName(impact.nrr_id),
        break_out: [],
        total_dollars: 0,
      }
      scenario.hbv_data.nrrGroups.push(nrrGroup);
    }

    // Add the impact to the NRR group

    nrrGroup.break_out.push(impact);
    nrrGroup.total_dollars += impact.dollar;
  }

  // Sort the groups by total dollar value (descending)
  scenario.hbv_data.nrrGroups = scenario.hbv_data.nrrGroups.sort((a: IHbvNRRGroup, b: IHbvNRRGroup) => b.total_dollars - a.total_dollars);

  // Within each group, sort the impacts by dollar value (descending).
  //
  // Also assigns chart colors for each impact.

  for(let i=0; i < scenario.hbv_data.nrrGroups.length; i++)
  {
    // Sort the impacts of this group by dollar value (descending)
    scenario.hbv_data.nrrGroups[i].break_out = scenario.hbv_data.nrrGroups[i].break_out.sort((a: IHbvImpact, b: IHbvImpact) => b.dollar - a.dollar);

    // Assign a chart color for each impact in this group.
    for(let j=0; j < scenario.hbv_data.nrrGroups[i].break_out.length; j++)
    {
      const impact: IHbvImpact = scenario.hbv_data.nrrGroups[i].break_out[j];

      // Assign a color for this impact (used for the chart)
      // It's a color selected from a pre-defined list of colors.
      //
      // NOTE: It's keyed off the impact_id, so when comparing multiple
      //       scenarios, the same impact will get the same color in all
      //       scenarios being compared.
      if(impact.impact_id)
        impact.color = GetChartColor(impact.impact_id.toString());
      else
        impact.color = '#707070';
    }
  }
}