// AOI attribute operations

import Debug from "../../Debug";
import useStore from "../../store";
import { IAoiAttribExpression, IAoiExpressionClause } from "./AoiAttribExpressionInterfaces";
import { IAoiAttribute, IAoiAttributeValue, IAoiGroupProperties } from "./AoiGroupInterfaces";


// NOTE: user-defined attributes start with this ID and go up (all admin attributes IDs should be below this number)
const USER_AOI_ATTRIBUTES_STARTING_ID = 100;



//-------------------------------------------------------------------------------
// Return the specified AOI attribute.
// NOTE: The AOI attribute list is specified.  We can't just use the one from the
//       state store, because that was is not 'current' when this method is called
//       from any of the editor windows.
//-------------------------------------------------------------------------------
export function GetAoiAttribute(aoi_attributes: IAoiAttribute[] | undefined, aoi_attribute_id: number | undefined): IAoiAttribute | undefined
{
  if(aoi_attributes === undefined || aoi_attribute_id === undefined) return undefined;

  return aoi_attributes.find(a => a.id === aoi_attribute_id);
}

//-------------------------------------------------------------------------------
// Return the AOI attribute value for a specified AOI.
// NOTE:  This version gets its data from the specified aoi_attributes list, 
//        not from the state store.  It is used in various editor windows.
//-------------------------------------------------------------------------------
export function GetAoiAttributeValueForAnAoi(aoi_attributes: IAoiAttribute[] | undefined, aoi_attribute_id: number | undefined, aoi_id: number | undefined): IAoiAttributeValue | undefined
{
  if(aoi_attributes === undefined || aoi_attribute_id === undefined || aoi_id === undefined) return undefined;
  
  for(let i=0; i < aoi_attributes.length; i++)
    if(aoi_attributes[i].id === aoi_attribute_id)
    {
      // Found the AOI attribute
      const value: IAoiAttributeValue | undefined = aoi_attributes[i].values.find(v => v.aoi_id === aoi_id);
      return value;
    }

  return undefined; // not found
}

//-------------------------------------------------------------------------------
// Return the AOI attribute value for a specified AOI.
// NOTE:  This version get its data from the state store.
//-------------------------------------------------------------------------------
export function GetAoiAttributeValueForAnAoi_StateStore(aoi_attribute_id: number | undefined, aoi_id: number | undefined): IAoiAttributeValue | undefined
{
  if(aoi_attribute_id === undefined || aoi_id === undefined) return undefined;
  
  const store_aoiGroupProps: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoiGroupProps) return undefined;

  return GetAoiAttributeValueForAnAoi(store_aoiGroupProps.attributes, aoi_attribute_id, aoi_id);
}

//-------------------------------------------------------------------------------
// Return the next ID for a new AOI attribute.
//-------------------------------------------------------------------------------
export function GetAoiAttributeNextID(attributeList: IAoiAttribute[]): number
{
  let nextID: number = USER_AOI_ATTRIBUTES_STARTING_ID;

  for(let i=0; i < attributeList.length; i++)
    if(attributeList[i].id >= nextID)
      nextID = attributeList[i].id + 1;

  return nextID;
}

//-------------------------------------------------------------------------------
// Returns all unique values for the specified AOI attribute.
//-------------------------------------------------------------------------------
export function GetAoiAttribUniqueValues(aoi_attributes: IAoiAttribute[] | undefined, 
                                         aoi_attribute_id: number|undefined,
                                         sort: boolean = false): string[] | undefined
{
  if(aoi_attributes === undefined || aoi_attribute_id === undefined) return undefined;

  const activeAttrib = GetAoiAttribute(aoi_attributes, aoi_attribute_id);
  if(!activeAttrib) return undefined;

  let uniqueValuesArr: string[] = activeAttrib.values.map(item => item.value).filter((value, index, self) => self.indexOf(value) === index);

  // If the "empty value" item is missing, add it.
  if(uniqueValuesArr.find(v => v === '') === undefined)
    uniqueValuesArr.push('');

  // Optional sorting
  if(sort === true)
  {
    if(activeAttrib.type === 'number')
      uniqueValuesArr = uniqueValuesArr.sort((a: string, b: string) => Number.parseFloat(a)! - Number.parseFloat(b!));
    else if(activeAttrib.type === 'text') // Sort such that empty values are at the end of the array
      uniqueValuesArr = uniqueValuesArr.sort((a: string, b: string) => {if (!a) return 1; if (!b) return -1; return a.localeCompare(b)});
  }
  
  return uniqueValuesArr;
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified AOI matches the specified AOI attribute expression.
// Retruns undefined if an error occurs.
//
// NOTE: This uses the AOI attribute data from the state store.
//-------------------------------------------------------------------------------
export function CheckAoiAttribExpressionMatch(aoi_id: number | undefined, 
                                              aoiAttribExpression: IAoiAttribExpression | undefined): boolean | undefined
{
  if(!aoi_id) return undefined;

  // If no expression is defined, by default we return a match
  if(!aoiAttribExpression) return true;

  // This array will store match results for each clause
  const clauseMatches: boolean[] = [];

  // We'll need the AOI attribute list from the state store
  const store_aoiGroupProps:  IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoiGroupProps) return undefined;

  // Determine TRUE/FALSE match for each clause

  for(let i=0; i < aoiAttribExpression.clauses.length; i++)
  {
    const clause: IAoiExpressionClause = aoiAttribExpression.clauses[i];

    if(clause.value === undefined)  // Clause values should never be empty
    {
      Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Numerical values in an AOI attrib clause is UNDEFINED');
      return undefined;
    }

    // Find the attribute this clause refers to
    const aoiAttrib: IAoiAttribute | undefined = GetAoiAttribute(store_aoiGroupProps.attributes, clause.attribute_id);
    if(!aoiAttrib) 
      return undefined;

    // Find the value of this attribute for this AOI
    const aoiAttribValue: IAoiAttributeValue | undefined = aoiAttrib.values.find(v => v.aoi_id === aoi_id);

    const isEmpty: boolean = aoiAttribValue === undefined || aoiAttribValue.value === undefined || aoiAttribValue.value.length === 0;

    // Check if the value matches the clause

    if(clause.type === 'number')
    {
      // === NUMBER COMPARISON ===

      // Make sure all values we are about to compare are numbers

      let aoiAttribValueNumber: number = Number.NaN;
      // NOTE: If the attrib value is undefined or empty, we allow it so that the 'is empty' and 'is not empty' operators
      //       will work in code further down.
      if(aoiAttribValue !== undefined && isEmpty === false) // TS won't allow just isEmpty check :(
      {
        aoiAttribValueNumber = Number.parseFloat(aoiAttribValue.value);
        if(isNaN(aoiAttribValueNumber))
        {
          Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Numerical AOI attrib value is not a number');
          return undefined;
        }
      }

      const clauseValueNumber: number = Number.parseFloat(clause.value);

      // Normally we need to make sure the clause value is a number, except for the 'is empty' operators, 
      // which have no clause value.
      if(clause.operator !== 'is empty' && clause.operator !== 'is not empty' && isNaN(clauseValueNumber))
      {
        Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Numerical value in an AOI attrib clause is not a number');
        return undefined;
      }

      let clauseValueMaxNumber: number = Number.NaN;
      if(clause.operator === 'is in range' || clause.operator === 'is not in range')
      {
        if(clause.value_max !== undefined)
        {
          clauseValueMaxNumber = Number.parseFloat(clause.value_max);
          if(isNaN(clauseValueMaxNumber))
          {
            Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Numerical AOI attrib value RANGE MAX is not a number');
            return undefined;
          }
        }
      }

      // Compare according to the clause's operator
      
      let clauseMatch: boolean | undefined = undefined;

      switch(clause.operator)
      {
        case 'is equal to': clauseMatch = aoiAttribValueNumber === clauseValueNumber; break;
        case 'is not equal to': clauseMatch = aoiAttribValueNumber !== clauseValueNumber; break;
        case 'is less than': clauseMatch = aoiAttribValueNumber < clauseValueNumber; break;
        case 'is less than or equal to': clauseMatch = aoiAttribValueNumber <= clauseValueNumber; break;
        case 'is greater than': clauseMatch = aoiAttribValueNumber > clauseValueNumber; break;
        case 'is greater than or equal to': clauseMatch = aoiAttribValueNumber >= clauseValueNumber; break;
        case 'is in range': clauseMatch = aoiAttribValueNumber >= clauseValueNumber && aoiAttribValueNumber <= clauseValueMaxNumber; break;
        case 'is not in range': clauseMatch = aoiAttribValueNumber < clauseValueNumber || aoiAttribValueNumber > clauseValueMaxNumber; break;
        case 'is empty': clauseMatch = isEmpty; break;
        case 'is not empty': clauseMatch = isEmpty === false; break;
        default:
          Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Invalid or unsupported NUMERICAL AOI attribute expression clause operator');
          return undefined;
      }

      if(clauseMatch === undefined)
        return undefined; // something went wrong

      // Add the boolean result for this clause to the list
      clauseMatches.push(clauseMatch);
    }
    else if(clause.type === 'text')
    {
      // === TEXT COMPARISON ===

      // Compare according to the clause's operator
      
      let clauseMatch: boolean | undefined = undefined;

      const aoiAttribValueNorm: string | undefined = aoiAttribValue?.value?.toLowerCase();
      const clauseValueNorm: string | undefined = clause?.value?.toLowerCase();

      switch(clause.operator)
      {
        case 'is equal to': clauseMatch = aoiAttribValueNorm === clauseValueNorm; break;
        case 'is not equal to': clauseMatch = isEmpty || aoiAttribValueNorm !== clauseValueNorm; break;
        case 'begins with': clauseMatch = aoiAttribValueNorm !== undefined && aoiAttribValueNorm.startsWith(clauseValueNorm); break;
        case 'does not begin with': clauseMatch = isEmpty || (aoiAttribValueNorm !== undefined && aoiAttribValueNorm.startsWith(clauseValueNorm) === false); break;
        case 'ends with': clauseMatch = aoiAttribValueNorm !== undefined && aoiAttribValueNorm.endsWith(clauseValueNorm); break;
        case 'does not end with': clauseMatch = isEmpty || (aoiAttribValueNorm !== undefined && aoiAttribValueNorm.endsWith(clauseValueNorm) === false); break;
        case 'contains': clauseMatch = aoiAttribValueNorm !== undefined && aoiAttribValueNorm.includes(clauseValueNorm); break;
        case 'does not contain': clauseMatch = isEmpty || (aoiAttribValueNorm !== undefined && aoiAttribValueNorm.includes(clauseValueNorm) === false); break;
        case 'is empty': clauseMatch = isEmpty; break;
        case 'is not empty': clauseMatch = isEmpty === false; break;
        default:
          Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Invalid or unsupported TEXT AOI attribute expression clause operator');
          return undefined;
      }

      if(clauseMatch === undefined)
        return undefined; // something went wrong

      // Add the boolean result for this clause to the list
      clauseMatches.push(clauseMatch);
     
    }
    else // should never happen
    {
      Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Invalid or unsupported AOI attribute expression clause type');
      return undefined;
    }
  }

  // Now we can join the clauses based on the main expression operator (AND/OR)

  if(aoiAttribExpression.operator === 'and')
  {
    // AND all the clauses

    // If any clause is FALSE, we return FALSE for the whole thing

    for(let i=0; i < clauseMatches.length; i++)
      if(clauseMatches[i] === false)
        return false;

    return true;
  }
  else if(aoiAttribExpression.operator === 'or')
  {
    // OR all the clauses

    // If any clause is TRUE, we return TRUE for the whole thing

    for(let i=0; i < clauseMatches.length; i++)
      if(clauseMatches[i] === true)
        return true;

    return false;
  }
  else // should never happen
  {
    Debug.error('AoiAttributeOps:CheckAoiAttribExpressionMatch> Invalid or unsupported AOI attribute expression operator');
    return undefined;
  }

  //return undefined;
}