// Portfolio Map operations

import { ZoomMapToGeojsonExtent } from "../../Map/MapOps";
import useStore from "../../store";
import { IAoi } from "../AoiInterfaces";
import { AddMultipleAoiLayersToMap, MergeAllAois, RemoveAoiLayersFromMap } from "../AoiOps";
import { GetAoiAttributeValueForAnAoi, GetAoiAttributeValueForAnAoi_StateStore } from "./AoiAttributeOps";
import { IAoiGroupProperties, IAoiAttribute, IAoiAttributeValue } from "./AoiGroupInterfaces";
import { AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP, AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP } from "./EditAoiGroupProperties";
import { IPortfolioMapColorScheme } from "./PortfolioMapInterfaces";


//-------------------------------------------------------------------------------
// Returns TRUE if the specified AOI is included in the portfolio map.
// NOTE: If anything goes wrong, the default is returned.
//-------------------------------------------------------------------------------
export function IsAoiInPortfolioMap(aoi_id: number): boolean
{
  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoi_group_props) return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;

  const store_aoi_attributes: IAoiAttribute[] = store_aoi_group_props.attributes;
  if(!store_aoi_attributes) return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;

  for(let i=0; i < store_aoi_attributes.length; i++)
    if(store_aoi_attributes[i].id === AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP)
    {
      // Found the AOI attribute
      const attribValue: IAoiAttributeValue | undefined = store_aoi_attributes[i].values.find(v => v.aoi_id === aoi_id);
      if(attribValue === undefined)
        return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP;

      return attribValue.value.toUpperCase() === 'TRUE' ? true : false;
    }

  return AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP; // not found
}

//-------------------------------------------------------------------------------
// Returns the active portfolio map color scheme, or undefined if none are active.
//-------------------------------------------------------------------------------
export function GetPortfolioMapActiveColorScheme(): IPortfolioMapColorScheme | undefined
{
  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoi_group_props) return undefined;

  if(!store_aoi_group_props.portfolio_map || !store_aoi_group_props.portfolio_map.active_color_scheme_id) return undefined;

  return store_aoi_group_props.portfolio_map.color_schemes.find(cs => cs.id === store_aoi_group_props.portfolio_map.active_color_scheme_id);
}

//-------------------------------------------------------------------------------
// Update the portfolio map.
//-------------------------------------------------------------------------------
export function UpdateAoiPortfolioMap()
{
  RemoveAoiLayersFromMap();

  const store_portfolioMapAois = useStore.getState().store_portfolioMapAois;
  if(!store_portfolioMapAois) return;

  // First, remove any AOIs from the list that are not included in the portfolio map

  const aois: IAoi[] = [];
  for(let i=0; i < store_portfolioMapAois.length; i++)
  {
    let include_in_PORTFOLIO_MAP: string | undefined = GetAoiAttributeValueForAnAoi_StateStore(AOI_ADMIN_ATTRIBUTE_ID_INCLUDE_IN_PORTFOLIO_MAP, store_portfolioMapAois[i].aoi_id)?.value;
    if(!include_in_PORTFOLIO_MAP)
      include_in_PORTFOLIO_MAP = AOI_ATTRIBUTE_DEFAULT_INCLUDE_IN_PORTFOLIO_MAP === true ? 'TRUE' : 'FALSE';

    if(include_in_PORTFOLIO_MAP.toUpperCase() === 'TRUE')
      aois.push(store_portfolioMapAois[i]);
  }

  // Merge the remaining AOIs into a single AOI
  const mergedAoi: IAoi = MergeAllAois('Portfolio Map', aois);
  useStore.getState().store_setAoi(mergedAoi);

  // Add the AOIs to the map as many individual Mapbox layers (each styled accordigly)
  AddMultipleAoiLayersToMap(aois);

  // Zoom the map to the merged AOI geometry
  if(mergedAoi.geom)
    ZoomMapToGeojsonExtent(mergedAoi.geom);
}
