// Edit user values

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import useStore from "./store";
import { GetNrrListForImpact } from "./Bundles/BundleOps";
import CloseIcon from '@mui/icons-material/Close';
import { theme_bgColorLight1, theme_textColorMain, theme_textColorBlended, theme_bgColorGradient2, theme_orange, theme_limeGreen, theme_errorRed } from "./Theme";
import { CustomTextField } from "./LayerLibrary/EditLayer/EditLayer";
import { IProjectUserSettingsUserValue } from "./Projects/ProjectInterfaces";
import Debug from "./Debug";
import { SaveActiveProject } from "./Projects/ProjectOps";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useState } from "react";
import { CallServer } from "./CallServer";
import { ToastNotification } from "./ToastNotifications";
import MyCircularProgress from "./Components/CircularProgress";
import { INRRImpact } from "./Bundles/BundleInterfaces";
import { FriendlyNumber } from "./Globals";


const ESI_MAX_LENGTH = 10;
const ESIV_MAX_LENGTH = 10;

// The local version adds min/max to facilitate the "Show User Value Range" functionality
// interface IProjectUserSettingsUserValueLOCAL extends IProjectUserSettingsUserValue
// {
//   min?: number;
//   max?: number;
//   //rangeAPIRunning?: boolean;
// }


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditUserValuesProps
{
}

//-------------------------------------------------------------------------------
// Edit user values
//-------------------------------------------------------------------------------
export function EditUserValues(props: EditUserValuesProps) 
{
  // Get needed state data from the store
  const { store_editUserValues, store_editUserValuesNrrID, store_editUserValuesImpactID, 
          store_nrrImpacts, store_setEditUserValues, store_setEditUserValuesNrrID,
          store_setEditUserValuesImpactID, store_project, store_aoi, 
        } = useStore();


  //const [localUserValues, setLocalUserValues] = useState<IProjectUserSettingsUserValueLOCAL[] | undefined>(undefined);

  const [getRangeIsRunning, setGetRangeIsRunning] = useState<boolean>(false);
  
  const [getRangeIsRunning_nrr_id, setGetRangeIsRunning_nrr_id] = useState<number|undefined>(undefined);
  const [getRangeIsRunning_impact_id, setGetRangeIsRunning_impact_id] = useState<number|undefined>(undefined);

  const [getValueRangeList, setValueRangeList] = useState<ICustomValuesRangeItem[]>([]);
  








  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  // useEffect(() => 
  // {

  //   if(store_project)
  //     setLocalUserValues(store_project.user_settings.user_values);

  // }, [store_project?.user_settings.user_values, store_editUserValuesNrrID, store_editUserValuesImpactID]);

  
  //-------------------------------------------------------------------------------
  // Cancel without saving changes.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    store_setEditUserValues(false);
    store_setEditUserValuesNrrID(undefined);
    store_setEditUserValuesImpactID(undefined);
    setValueRangeList([]);
  }
/*
  //-------------------------------------------------------------------------------
  // The ESI text field has changed.
  //-------------------------------------------------------------------------------
  function OnESIChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, nrr_id: number, impact_id: number): void 
  {
    const newValueStr: string = event.target.value;
    
    const newValue: number | undefined = Number.parseInt(newValueStr);
    if(!newValue) return;

    const newUserValue: IProjectUserSettingsUserValue = 
    {
      nrr_id: nrr_id,
      impact_id: impact_id,
      value: newValue
    }

    // If a user value for this ESI already exists, update it - otherwise add a new entry

    let newUserValues: IProjectUserSettingsUserValue[] = [];

    let userValue: IProjectUserSettingsUserValue | undefined = localUserValues.find(uv => uv.nrr_id === nrr_id && uv.impact_id === impact_id);
    if(userValue) // already exists - update it
      newUserValues = localUserValues.map(uv => uv.nrr_id === nrr_id && uv.impact_id === impact_id ? newUserValue : uv);
    else // doesn't exist - add it
      newUserValues = [...localUserValues, newUserValue];

    setLocalUserValues(newUserValues);
  }    

  //-------------------------------------------------------------------------------
  // The ESIV text field has changed.
  //
  // NOTE: An ESIV/impact_id can appear in multiple NRRs, so this change could cause
  //       multiple ESIV text boxes to update based on this value.
  //-------------------------------------------------------------------------------
  function OnESIVChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, impact_id: number): void 
  {
    const newValueStr: string = event.target.value;
    
    const newValue: number | undefined = Number.parseInt(newValueStr);
    if(!newValue) return;

    const newUserValue: IProjectUserSettingsUserValue = 
    {
      nrr_id: undefined,
      impact_id: impact_id,
      value: newValue
    }

    // If a user value for this ESIV already exists, update it - otherwise add a new entry

    let newUserValues: IProjectUserSettingsUserValue[] = [];

    let userValue: IProjectUserSettingsUserValue | undefined = localUserValues.find(uv => uv.nrr_id === undefined && uv.impact_id === impact_id);
    if(userValue) // already exists - update it
      newUserValues = localUserValues.map(uv => uv.nrr_id === undefined && uv.impact_id === impact_id ? newUserValue : uv);
    else // doesn't exist - add it
      newUserValues = [...localUserValues, newUserValue];

    setLocalUserValues(newUserValues);
  }
*/
  //-------------------------------------------------------------------------------
  // Form SUBMIT
  //-------------------------------------------------------------------------------
  const OnFormAccept = (event: React.FormEvent<HTMLFormElement>) => 
  {
    if(!store_project) return;

    event.preventDefault();

    //setErrorMessage('');

    // Get form data and validate
    //
    // NOTE: If the window was opened in "single-impact" mode, all other elements are still
    //       rendered, but with display='none', so they will not actually appear to the user.
    //       That means the FormData will still contain ALL nrr/impact user values, not just 
    //       the single one being edited by the user.

    const data = new FormData(event.currentTarget);

    let editedUserValues: IProjectUserSettingsUserValue[] = [];
    for (const pair of data.entries()) 
    {
      const newItem: IProjectUserSettingsUserValue | undefined = CreateEditedItemFromDataEntry(pair);
      if(!newItem) continue;

      editedUserValues.push(newItem);
    }
/*
    // If the form was opened in "Single impact" mode (not all nrrs+impacts), then we 
    // only update the existing array of user values stored in the active project.

    if(store_editUserValuesImpactID && store_project.user_settings.user_values && store_project.user_settings.user_values.length >= 1)
    {
      const newUserValues: IProjectUserSettingsUserValue[] = [];
      for(let i=0; i < store_project.user_settings.user_values.length; i++) // .map(uv => uv.nrr_id === nrr_id && uv.impact_id === impact_id ? newUserValue : uv);
      {
        const existingUserValue: IProjectUserSettingsUserValue = store_project.user_settings.user_values[i];
        const editedUserValue: IProjectUserSettingsUserValue | undefined = editedUserValues.find(euv => euv.nrr_id === existingUserValue.nrr_id && euv.impact_id === existingUserValue.impact_id);

        // For each user value in the project we either pick the existing (unchanged) value, or 
        // the new edited value (if there is one).
        
        newUserValues.push(editedUserValue ? editedUserValue : existingUserValue);
      }

      useStore.getState().store_setProject_UserValues(newUserValues);
    }
    else
    {
      // The form was opened to all nrrs and impacts - we can just replace it completely.

      useStore.getState().store_setProject_UserValues(editedUserValues);
    }
*/

    // Replace the user values stored in the active project with the new updated set
    useStore.getState().store_setProject_UserValues(editedUserValues);

    // Force-save the active project
    SaveActiveProject();

    // Close the window
    OnClose();
  }

  //-------------------------------------------------------------------------------
  // Parses a form data esi or esiv item into a usable 'IProjectUserSettingsUserValue' item.
  //-------------------------------------------------------------------------------
  function CreateEditedItemFromDataEntry(pair: [string, FormDataEntryValue]): IProjectUserSettingsUserValue | undefined
  {
    const key = pair[0];
    const valueStr = pair[1];
    if(!valueStr || valueStr === '') return undefined;
    const valueNum: number = Number.parseFloat(valueStr as string);
    if(!valueNum) return undefined;

    // The name/key is formatted in 2 ways:
    //
    // esi|nrr_id|impact_id
    // esiv|impact_id

    const strArr: string[] = key.split("|");
    if(strArr.length === 3 && strArr[0] === 'esi')
    {
      // This is an ESI item:  esi|nrr_id|impact_id

      const newItem: IProjectUserSettingsUserValue = 
      {
        nrr_id: Number.parseInt(strArr[1]),
        impact_id: Number.parseInt(strArr[2]),
        value: valueNum
      };
      return newItem;
    }
    else if(strArr.length === 2 && strArr[0] === 'esiv')
    {
      // This is an ESIV item:  esiv|impact_id

      const newItem: IProjectUserSettingsUserValue = 
      {
        nrr_id: undefined,
        impact_id: Number.parseInt(strArr[1]),
        value: valueNum
      };
      return newItem;
    }

    Debug.error(`EditUserValues.CreateEditedItemFromDataEntry> Encountered an invalid key/name when processing the submitted form`);
    return undefined;
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function OnGetRangeForESIV(impact: INRRImpact)
  {
    GetRangeForUserValue(undefined, impact.impact_id, 'ESIV');
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function OnGetRangeForESI(nrr_id: number, impact_id: number)
  {
    GetRangeForUserValue(nrr_id, impact_id, 'ESI');
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  async function GetRangeForUserValue(nrr_id: number | undefined, impact_id: number, typeStr: string): Promise<boolean>
  {
    if(!store_aoi) return false;

    const item: any = { impact_id: impact_id };
    if(nrr_id)
      item.nrr_id = nrr_id;

    // Call the server to get the range

    const itemArr = [ item ];

    const server = new CallServer();
    server.Add("aoi_id", store_aoi.aoi_id);
    server.Add("data", itemArr);
    
    setGetRangeIsRunning(true);
    setGetRangeIsRunning_nrr_id(nrr_id);
    setGetRangeIsRunning_impact_id(impact_id);

    const result = await server.Call('post', '/range');

    setGetRangeIsRunning(false);
    setGetRangeIsRunning_nrr_id(undefined);
    setGetRangeIsRunning_impact_id(undefined);

    if(result.success)
    {
      Debug.log('EditUserValues.GetRangeForUserValue> API server call SUCCESS');
      //Debug.log('EditUserValues.GetRangeForUserValue> SUCCESS! data=' + JSON.stringify(result.data));

      const data: any[] = result.data;
      
      if(!data || data.length !== 1)
      {
        ToastNotification('error', "Unable to get value range (1)")
        Debug.error(`EditUserValues.GetRangeForUserValue> Received invalid data`);
        return false;
      }

      const min: number = data[0].min;
      const max: number = data[0].max;

      if(min === undefined || max === undefined || min === null || max === null)
      {
        ToastNotification('error', "Unable to get value range (2)")
        Debug.error(`EditUserValues.GetRangeForUserValue> Received invalid min/max data`);
        return false;
      }

      // Remove the old entry (if it exists)
      const updatedValuesRangeList: ICustomValuesRangeItem[] = getValueRangeList.filter(i => i.nrr_id !== nrr_id || i.impact_id !== impact_id);

      // Add the new entry
      const newValueRangeItem: ICustomValuesRangeItem = 
      {
        nrr_id: nrr_id,
        impact_id: impact_id,
        typeStr: typeStr,
        min: min,
        max: max
      }
      updatedValuesRangeList.push(newValueRangeItem);

      // Update the list
      setValueRangeList(updatedValuesRangeList);

      // Show the user the range
      //ToastNotification('success', `${typeStr} range for the active AOI:  ${FriendlyNumber(min,3)} to ${FriendlyNumber(max,3)}`);
      
      Debug.log(`EditUserValues.GetRangeForUserValue> Value range data received`);
      return true;  // success
    }
    else
    {
      // Failure
      ToastNotification('error', "Unable to get value range")
      Debug.error(`EditUserValues.GetRangeForUserValue> ${result.errorCode} - ${result.errorMessage}`);
      return false;
    }
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function RenderValueRange(nrr_id: number | undefined, impact_id: number)
  {
    const valueRangeItem: ICustomValuesRangeItem | undefined = getValueRangeList.find(i => i.nrr_id === nrr_id && i.impact_id === impact_id);
    if(!valueRangeItem) return null;

    return (
      <Typography sx={{ width: '100%', textAlign: 'end', fontSize: '0.7rem', color: theme_orange }}>
        {valueRangeItem.typeStr} range for the active AOI:  <b>{FriendlyNumber(valueRangeItem.min,3)} - {FriendlyNumber(valueRangeItem.max,3)}</b>
      </Typography>
    )
  }
















  if(!store_project) return null;

  return (


    <Box component="form" noValidate onSubmit={OnFormAccept}>
    <Dialog disablePortal open={store_editUserValues===true} onClose={OnClose} maxWidth='xl' 
            PaperProps={{ sx: { minWidth: '30%', width: '45%', maxWidth: '45%', maxHeight: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ ml: 1, fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
            Customize Land Management Values
          </Typography>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 0, background: theme_bgColorGradient2, p: 2 }}>

        {store_nrrImpacts.map(function(impact)
        {
          // We don't simply prevent rendering of other user value UI elements when in single-impact mode - we want the 
          // FormData that gets submitted to contain ALL user values, not just the one the user is editing - that makes it
          // very easy to replace the whole list in the active project with this new list.

          const display: any = store_editUserValuesImpactID !== undefined && impact.impact_id !== store_editUserValuesImpactID ? 'none' : undefined;

          //const userValue: IProjectUserSettingsUserValueLOCAL | undefined = localUserValues?.find(v => v.nrr_id === undefined && v.impact_id === impact.impact_id);
          const userValue: IProjectUserSettingsUserValue | undefined = store_project.user_settings?.user_values?.find(v => v.nrr_id === undefined && v.impact_id === impact.impact_id);

          return (

            <Stack key={impact.impact_id} sx={{ my: 3 }} display={display}>

              <Stack>

                <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between', bgcolor: theme_bgColorLight1+'65', borderRadius: 2 }}>

                  <Stack direction='row' sx={{ alignItems: 'center' }}>

                    <Typography sx={{ px: 1.5, py: 0.4, color: theme_orange+'E0', fontSize: '1.1rem', fontWeight: 'bold' }}>
                      {impact.name}
                    </Typography>

                    {/* ESIV */}

                    <CustomTextField name={`esiv|${impact.impact_id}`} variant='filled' type='number' size='small' autoComplete='off' 
                                      //defaultValue={store_project.user_settings.user_values?.find(v => v.nrr_id === undefined && v.impact_id === impact.impact_id)?.value}
                                      defaultValue={userValue?.value}
                                      inputProps={{ maxLength: ESIV_MAX_LENGTH }}
                                      disabled={getRangeIsRunning}
                                      label=
                                      {
                                        <Stack direction='row' sx={{ alignItems: 'center' }}>
                                          <Typography sx={{ color: theme_textColorBlended, fontSize: '1rem' }}>
                                            Value
                                          </Typography>
                                          <Typography sx={{ ml: 1, color: theme_errorRed+'A7', fontSize: '1rem' }}>
                                            $
                                          </Typography>
                                        </Stack>
                                      }
                                      sx={{ px: 0, py: 0, mt: 0, ml: 2, width: '150px' }}/>
                  </Stack>

                  {/* "Get Value Range" button */}

                  <Stack sx={{ mr: 1 }}>
                    {impact.impact_id === getRangeIsRunning_impact_id && getRangeIsRunning_nrr_id === undefined
                      ?
                        <MyCircularProgress />
                      :
                        <Tooltip title={`Get the value range of this ESIV (${impact.name}) for your active AOI`}>
                          <IconButton onClick={(_)=>OnGetRangeForESIV(impact)} disabled={getRangeIsRunning}>
                            <CompareArrowsIcon sx={{ color: theme_limeGreen, p: 0 }} />
                          </IconButton>
                        </Tooltip>
                    }
                  </Stack>

                </Stack>

                {RenderValueRange(undefined,impact.impact_id)}

              </Stack>

              <Typography sx={{ ml: 1.5, mt: 2, fontSize: '1rem', color: theme_limeGreen+'C0' }}>
                Ecosystem Service Impacts (ESI):
              </Typography>

              {GetNrrListForImpact(impact.impact_id).map(function(nrr)
              {
                // We don't simply prevent rendering of other user value UI elements when in single-impact mode - we want the 
                // FormData that gets submitted to contain ALL user values, not just the one the user is editing - that makes it
                // very easy to replace the whole list in the active project with this new list.

                const display: any = store_editUserValuesNrrID === undefined || nrr.nrr_id === store_editUserValuesNrrID ? undefined : 'none';

                const userValue: IProjectUserSettingsUserValue | undefined = store_project.user_settings?.user_values?.find(v => v.nrr_id === nrr.nrr_id && v.impact_id === impact.impact_id);

                return (
                  <Stack direction='column' key={nrr.nrr_id} display={display} 
                         sx={{ ml: 5, mt: 2, mb: 0.5, }} >

                    <Stack direction='row' sx={{ width: '100%' }}>

                      {/* ESI */}

                      <CustomTextField name={`esi|${nrr.nrr_id}|${impact.impact_id}`} variant='standard' type='number' size='small' autoComplete='off'
                                        //defaultValue={store_project.user_settings.user_values?.find(v => v.nrr_id === nrr.nrr_id && v.impact_id === impact.impact_id)?.value}
                                        defaultValue={userValue?.value}
                                        inputProps={{ maxLength: ESI_MAX_LENGTH }}
                                        disabled={getRangeIsRunning}
                                        label=
                                        {
                                          <Stack direction='row' sx={{ alignItems: 'center' }}>
                                            <Typography sx={{ color: theme_textColorBlended, fontSize: '0.9rem' }}>
                                              {nrr.name}
                                            </Typography>
                                            <Typography sx={{ ml: 1, color: theme_errorRed+'A4', fontSize: '0.9rem' }}>
                                              {impact.unit}
                                            </Typography>
                                          </Stack>
                                        }
                                        sx={{ width: '100%', p:0, mt: 0 }}/>

                      {/* "Get Value Range" button */}

                      <Stack sx={{ mr: 1 }}>
                        {impact.impact_id === getRangeIsRunning_impact_id && nrr.nrr_id === getRangeIsRunning_nrr_id
                          ?
                            <MyCircularProgress />
                          :
                            <Tooltip title={`Get the value range of this ESI for your active AOI`}>
                              <IconButton onClick={(_)=>OnGetRangeForESI(nrr.nrr_id,impact.impact_id)} disabled={getRangeIsRunning}>
                                <CompareArrowsIcon sx={{ color: theme_limeGreen, p: 0 }} />
                              </IconButton>
                            </Tooltip>
                        }
                      </Stack>

                    </Stack>

                    {RenderValueRange(nrr.nrr_id,impact.impact_id)}

                  </Stack>
                )
              })}

            </Stack>
          )
        })}

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* Error message */}

          {/* {errorMessage && errorMessage.length > 0
            ?
              <Typography sx={{ mb: 3, fontSize: '1.1rem', fontWeight:'normal', color: theme_errorRed, opacity: 1,
                                textAlign: 'center', borderRadius: 1, bgcolor: theme_errorRed+'30', px: 2, py:1 }}>
                {errorMessage}
              </Typography>
            :null
          } */}

          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button type="submit" variant='contained' sx={{ width: '200px', fontWeight: 'bold' }}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
            
    </Dialog>
    </Box>
  )
}


interface ICustomValuesRangeItem
{
  nrr_id?: number;
  impact_id: number;
  typeStr: string; // ESI or ESIV
  min: number;
  max: number;
}