// AOI group operations

import { CallServer } from "../../CallServer";
import Debug from "../../Debug";
import useStore from "../../store";
import { ToastNotification } from "../../ToastNotifications";
import { IAoiGroupProperties, IAoiAttribute } from "./AoiGroupInterfaces";


//-------------------------------------------------------------------------------
// Save AOI group properties.
//-------------------------------------------------------------------------------
export async function SaveAoiGroupProperties(newAoiGroupProperties: IAoiGroupProperties): Promise<boolean>
{
  if(!newAoiGroupProperties)
  {
    Debug.warn(`AoiOps.SaveAoiGroupProperties> null newAoiGroupProperties`);
    return false;
  }
  
  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.warn(`AoiOps.SaveAoiGroupProperties> null project, project id, or aoi group id`);
    return false;
  }

  // Call server to save the data

  const server = new CallServer();
  server.Add('aoi_group_id', store_project.aoi_group_id);
  server.Add("aoi_group_properties", newAoiGroupProperties);
  
  useStore.getState().store_setAoiGroupPropertiesIsSaving(true);

  const result = await server.Call('put', '/aoi_group_props');

  useStore.getState().store_setAoiGroupPropertiesIsSaving(false);

  if(result.success)
  {
    Debug.log('AoiOps.SaveAoiGroupProperties> API server call SUCCESS');
    //Debug.log('AoiOps.SaveAoiGroupProperties> SUCCESS! data=' + JSON.stringify(result.data));

    // No need to update the state store or the map here, those are already done before this call is made
    //useStore.getState().store_setAoiGroupProperties(newAoiGroupProperties);

    // Success
    Debug.log(`AoiOps.SaveAoiGroupProperties> AOI group properties saved.`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to save AOI group properties")
    Debug.error(`AoiOps.SaveAoiGroupProperties> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Returns the MIN value for the specified numerical AOI attribute.
//-------------------------------------------------------------------------------
export function GetAoiAttributeMinValue(aoi_attribute_id: number | undefined): number | undefined
{
  if(!aoi_attribute_id) return undefined;
  
  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoi_group_props) return undefined;

  const store_aoi_attributes: IAoiAttribute[] = store_aoi_group_props.attributes;
  if(!store_aoi_attributes) return undefined;

  for(let i=0; i < store_aoi_attributes.length; i++)
    if(store_aoi_attributes[i].id === aoi_attribute_id)
    {
      // If the attribute is not numerical, we are done here
      if(store_aoi_attributes[i].type !== 'number') return undefined;

      let minValueSoFar: number = Number.MAX_VALUE;
      for(let j=0; j < store_aoi_attributes[i].values.length; j++)
      {
        const valueNum: number = Number.parseFloat(store_aoi_attributes[i].values[j].value);
        if(valueNum < minValueSoFar)
          minValueSoFar = valueNum; // We found a new MIN value
      }

      if(minValueSoFar === Number.MAX_VALUE) 
        return undefined;

      return minValueSoFar;
    }

  return undefined; // attribute not found
}

//-------------------------------------------------------------------------------
// Returns the MAX value for the specified numerical AOI attribute.
//-------------------------------------------------------------------------------
export function GetAoiAttributeMaxValue(aoi_attribute_id: number | undefined): number | undefined
{
  if(!aoi_attribute_id) return undefined;
  
  const store_aoi_group_props: IAoiGroupProperties | undefined = useStore.getState().store_aoiGroupProperties;
  if(!store_aoi_group_props) return undefined;

  const store_aoi_attributes: IAoiAttribute[] = store_aoi_group_props.attributes;
  if(!store_aoi_attributes) return undefined;

  for(let i=0; i < store_aoi_attributes.length; i++)
    if(store_aoi_attributes[i].id === aoi_attribute_id)
    {
      // If the attribute is not numerical, we are done here
      if(store_aoi_attributes[i].type !== 'number') return undefined;

      let maxValueSoFar: number = Number.MIN_VALUE;
      for(let j=0; j < store_aoi_attributes[i].values.length; j++)
      {
        const valueNum: number = Number.parseFloat(store_aoi_attributes[i].values[j].value);
        if(valueNum > maxValueSoFar)
          maxValueSoFar = valueNum; // We found a new MAX value
      }

      if(maxValueSoFar === Number.MIN_VALUE) 
        return undefined;

      return maxValueSoFar;
    }

  return undefined; // attribute not found
}

//-------------------------------------------------------------------------------
// Returns the number of non-admin AOI attributes.
//-------------------------------------------------------------------------------
export function GetNonAdminAoiAttribCount(aoiGroupProps: IAoiGroupProperties)
{
  let count: number = 0;
  for(let i=0; i < aoiGroupProps.attributes.length; i++)
    if(aoiGroupProps.attributes[i].is_admin === false)
      count++;

  return count;
}