// Parcels search expression builder panel

import useStore from "../store";
import { FormControl, IconButton, InputLabel, MenuItem, SelectChangeEvent, Stack, styled, TextField, Typography } from "@mui/material";
import { theme_bgColorLight1,theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { GetParcelAttributes } from "./ParcelOps";
import { ParcelSearchSelect } from "./ParcelSearch";
import { IParcelSearchClause, ParcelSearchStringOperators, ParcelSearchNumberOperators, TParcelSearchStringOperator, ParcelSearchEnumOperators } from "./ParcelInterfaces";
import DeleteIcon from '@mui/icons-material/Delete';
import { IVectorLayerAttribute } from "../Layers/LayerInterfaces";
import { GetParcelLayerAttribute } from "../AttributeOps";
import AttributeHelp from "../Components/AttributeHelp";



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ParcelSearchBuilderProps
{
}

//-------------------------------------------------------------------------------
// Parcels search expression builder panel
//-------------------------------------------------------------------------------
const ParcelSearchBuilder = (props: ParcelSearchBuilderProps) => 
{
  // Get needed state data from the store
  const { store_project, store_parcelSearchExpression, store_removeParcelSearchClause, 
          store_updateParcelSearchClause
        } = useStore();



        



  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function RenderSearchClauseOperatorComboBox(searchClause: IParcelSearchClause)
  {
    if(!searchClause) return null;

    if(searchClause.data_type === 'string')
    return (
      ParcelSearchStringOperators.map(operatorStr => 
        <MenuItem key={operatorStr} value={operatorStr}>{operatorStr}</MenuItem>
      )
    )

    if(searchClause.data_type === 'number')
    return (
      ParcelSearchNumberOperators.map(operatorStr => 
        <MenuItem key={operatorStr} value={operatorStr}>{operatorStr}</MenuItem>
      )
    )

    if(searchClause.data_type === 'enum')
    return (
      ParcelSearchEnumOperators.map(operatorStr => 
        <MenuItem key={operatorStr} value={operatorStr}>{operatorStr}</MenuItem>
      )
    )

    return (
      <MenuItem value='is equal to'>is equal to</MenuItem>
    )
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function RenderSearchClauseEnumValuesComboBox(searchClause: IParcelSearchClause)
  {
    if(!searchClause || searchClause.data_type !== 'enum' || !searchClause.attribute_name) 
      return null;

    const parcelAttribute: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(searchClause.attribute_name);
    if(!parcelAttribute || !parcelAttribute.enum_list)
      return null;

    return (
      parcelAttribute.enum_list.map(enumValue =>
        <MenuItem key={enumValue} value={enumValue}>{enumValue}</MenuItem>
      )
    )
  }

  //-------------------------------------------------------------------------------
  // The user has selected a different attribute for one of the search clauses.
  //-------------------------------------------------------------------------------
  function onAttributeChanged(event: SelectChangeEvent<any>, searchClause: IParcelSearchClause)
  {
    const clickedAttributeName: string | number = event.target.value;
    if(!clickedAttributeName) return;
    if(typeof clickedAttributeName !== 'string') return;

    const attribute: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(clickedAttributeName);
    if(!attribute) return;

    const newSearchClause: IParcelSearchClause = 
    {
      ...searchClause,
      attribute_name: attribute.attribute_name,
      data_type: attribute.data_type,
      operator: 'is equal to',
      value: '',
      value_max: ''
    }

    store_updateParcelSearchClause(newSearchClause);
  }

  //-------------------------------------------------------------------------------
  // The user has selected a different operator for one of the search clauses.
  //-------------------------------------------------------------------------------
  function onClauseOperatorChanged(event: SelectChangeEvent<any>, searchClause: IParcelSearchClause)
  {
    const clickedOperator: TParcelSearchStringOperator = event.target.value as TParcelSearchStringOperator;
    if(!clickedOperator) return;

    if(!searchClause.attribute_name) return;

    const attribute: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(searchClause.attribute_name);
    if(!attribute) return;

    const newSearchClause: IParcelSearchClause = 
    {
      ...searchClause,
      operator: clickedOperator,
    }

    // If the selected operator is 'is empty' or 'is not empty', clear out any current value
    if(clickedOperator === 'is empty' || clickedOperator === 'is not empty')
    {
      newSearchClause.value = '';
      newSearchClause.value_max = '';
    }

    // If the selected operator is 'is like' or 'is not like', show a small "help" line telling users about wildcards
    if(clickedOperator === 'is like' || clickedOperator === 'is not like')
      newSearchClause.helpStr = 'Wildcards:  * matches one char,  % matches zero or more chars';
    else
      newSearchClause.helpStr = undefined;

    store_updateParcelSearchClause(newSearchClause);
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to an attribute value for one of the search clauses.
  //-------------------------------------------------------------------------------
  function onAttribValueChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, searchClause: IParcelSearchClause)
  {
    if(!searchClause.attribute_name) return;

    const newValue: string = event.target.value;

    const newSearchClause: IParcelSearchClause = 
    {
      ...searchClause,
      value: newValue,
    }

    store_updateParcelSearchClause(newSearchClause);
  }

  //-------------------------------------------------------------------------------
  // The user has made changes to an attribute's max value.
  //-------------------------------------------------------------------------------
  function onAttribMaxValueChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, searchClause: IParcelSearchClause)
  {
    if(!searchClause.attribute_name) 
      return;

    const newValueStr: string = event.target.value;

    const newSearchClause: IParcelSearchClause = 
    {
      ...searchClause,
      value_max: newValueStr,
    }

    store_updateParcelSearchClause(newSearchClause);
  }

  //-------------------------------------------------------------------------------
  // The user deleted a search clause.
  //-------------------------------------------------------------------------------
  function OnRemoveSearchClause(clause_id: number)
  {
    store_removeParcelSearchClause(clause_id);
  }

  //-------------------------------------------------------------------------------
  // The user has selected a new enum value for one of the search clauses.
  //-------------------------------------------------------------------------------
  function onEnumValueChanged(event: SelectChangeEvent<any>, searchClause: IParcelSearchClause)
  {
    const selectedEnumValue: string = event.target.value;

    const newSearchClause: IParcelSearchClause = 
    {
      ...searchClause,
      value: selectedEnumValue,
    }

     store_updateParcelSearchClause(newSearchClause);
  }

  //-------------------------------------------------------------------------------
  // Generate the label for the attribute "Value" UI element (min).
  //-------------------------------------------------------------------------------
  function GetValueLabelMin(searchClause: IParcelSearchClause): string
  {
    let label: string = searchClause.operator==='is in range' || searchClause.operator==='is not in range' ? 'Min Value' : 'Value';

    // If the attribute has a "unit" specified, include that in the label

    const parcelAttribute: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(searchClause.attribute_name);
    if(!parcelAttribute || !parcelAttribute.unit)
      return label;

    return label + ' (' + parcelAttribute.unit + ')';
  }

  //-------------------------------------------------------------------------------
  // Generate the label for the attribute "Value" UI element (max, for ranges only).
  //-------------------------------------------------------------------------------
  function GetValueLabelMax(searchClause: IParcelSearchClause): string
  {
    let label: string = 'Max Value';

    // If the attribute has a "unit" specified, include that in the label

    const parcelAttribute: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(searchClause.attribute_name);
    if(!parcelAttribute || !parcelAttribute.unit)
      return label;

    return label + ' (' + parcelAttribute.unit + ')';
  }













  // There must be an active project
  if(!store_project) return null;

  // Main render

  return (

    <Stack sx={{ mt: 1, overflow: 'auto', maxHeight: '250px', bgcolor: theme_bgColorLight1+'AA', 
                 borderRadius: 1, boxShadow: 1, px: 0.7 }}>

      {store_parcelSearchExpression?.clauses.map(searchClause =>

        <Stack key={searchClause.clause_id} direction='column' sx={{ mt: 0.7, mb: 0.7 }}>

          <Stack direction='row' sx={{ alignItems: searchClause.operator==='is in range' || searchClause.operator==='is not in range' ? 'center' : 'flex-end' }}>

            <Stack direction='row' sx={{ alignItems: 'flex-end' }}>

              {/* Attribute selector */}

              <FormControl variant="standard" sx={{ mr: 1, minWidth: 120 }}>
                <InputLabel id="attrib-name-label" sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>Attribute</InputLabel>
                <ParcelSearchSelect variant='standard' size='small' labelId='attrib-name-label' 
                              value={searchClause.attribute_name} onChange={(e)=>onAttributeChanged(e,searchClause)}>
                  {GetParcelAttributes().map(parcelAttribute =>
                    <MenuItem key={parcelAttribute.attribute_name} value={parcelAttribute.attribute_name} sx={{ px: '5px' }}>
                      <Stack direction='row' sx={{ alignItems: 'center' }}>
                        <AttributeHelp iconSize='16px'
                                       name={parcelAttribute.display_name} 
                                       description={parcelAttribute.description}
                                       units={parcelAttribute.unit}
                                       url={parcelAttribute.info_url}/>
                        <Typography sx={{ ml: '6px' }}>
                          {parcelAttribute.display_name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  )}
                </ParcelSearchSelect>
              </FormControl>

              {/* Operator selector */}

              <ParcelSearchSelect variant='standard' size='small' labelId='attrib-operator-label' 
                            value={searchClause.operator} onChange={(e)=>onClauseOperatorChanged(e,searchClause)}
                            sx={{ fontSize: '0.8rem', mr: 1, color: theme_limeGreen }}>
                  {RenderSearchClauseOperatorComboBox(searchClause)}
              </ParcelSearchSelect>

            </Stack>

            {/* Attribute Value: number, string */}

            {searchClause.data_type === 'number' || searchClause.data_type === 'string'
              ?
                <Stack sx={{ width: '100%' }}>

                  <ParcelSearchTextField size='small' variant='standard' 
                      label={GetValueLabelMin(searchClause)}
                      disabled={(searchClause.operator === 'is empty' || searchClause.operator === 'is not empty')}
                      value={searchClause.value} onChange={(e)=>onAttribValueChanged(e,searchClause)}
                      inputProps={{style: { fontSize: '1rem', color: theme_textColorMain, opacity: 0.8 }}} // font size of input text
                      InputLabelProps={{style: { fontSize: '0.8rem', color: theme_textColorBlended }}} // font size of input label
                      sx={{ minWidth: '120px', width: '100%' }}/>

                  {/* Max value (only for number ranges) */}
                  {searchClause.operator==='is in range' || searchClause.operator==='is not in range'
                    ?
                      <ParcelSearchTextField size='small' variant='standard' label={GetValueLabelMax(searchClause)}
                          value={searchClause.value_max} onChange={(e)=>onAttribMaxValueChanged(e,searchClause)}
                          inputProps={{style: { fontSize: '1rem', color: theme_textColorMain, opacity: 0.8 }}} // font size of input text
                          InputLabelProps={{style: { fontSize: '0.8rem', color: theme_textColorBlended }}} // font size of input label
                          sx={{ minWidth: '120px', width: '100%' }}/>
                    :null
                  }
                </Stack>
              :null
            }

            {/* Attribute value: enum */}

            {searchClause.data_type === 'enum'
              ?
                <FormControl variant="standard" sx={{ mr: 1, minWidth: 120, width: '100%' }}>
                  <InputLabel id="attrib-enum-value-label" sx={{ fontSize: '0.8rem', color: theme_textColorBlended }}>Value</InputLabel>
                  <ParcelSearchSelect variant='standard' size='small' labelId='attrib-enum-value-label' 
                                      value={searchClause.value} onChange={(e)=>onEnumValueChanged(e,searchClause)}>
                    {RenderSearchClauseEnumValuesComboBox(searchClause)}
                  </ParcelSearchSelect>
                </FormControl>
              :null
            }

            {/* Attribute value: NO ATTRIBUTE SELECTED */}

            {searchClause.data_type === undefined
              ?
                // Just a space placeholder so everything lines up in the UI
                <Stack sx={{ width: '100%' }}>
                </Stack>
              :null
            }

            {/* The "Delete Clause" button only appears if there are 2+ clauses */}

            {store_parcelSearchExpression.clauses.length > 1
              ?
                <IconButton sx={{p: 0.1, ml: 1}} onClick={(_)=>OnRemoveSearchClause(searchClause.clause_id)}>
                  <DeleteIcon sx={{ color: theme_textColorBlended, opacity: 0.7, p: 0.4 }}/>
                </IconButton>
              :null
            }

          </Stack>

          {/* Optional HELP message for this search clause (used to show a help message for like searches) */}

          {searchClause.helpStr && searchClause.helpStr.length > 0
            ?
              <Typography sx={{ mt: 0.5, fontSize: '0.7rem', color: theme_orange, opacity: 0.8, textTransform: 'none', textAlign: 'center' }}>
                {searchClause.helpStr}
              </Typography>
            :null
          }

        </Stack>
      )}

    </Stack>
  )
}


export default ParcelSearchBuilder;



// Customized MUI TextField
export const ParcelSearchTextField = styled(TextField)(() => (
{
  // These change the underline color when using the standard variant
  '& .MuiInput-underline:before': { borderBottomColor: theme_textColorBlended },
  '& .MuiInput-underline:after': { borderBottomColor: theme_textColorBlended },
}));
  